<ul class="nav navbar-nav align-items-center ml-auto" style="width: max-content;">
  <!-- Language selection -->
  <li class="nav-item dropdown dropdown-language">
    <a class="nav-link" *ngIf="_translateService.currentLang === 'ar'" (click)="setLanguage('en')">
      <span class="selected-language mx-25 font-small-2">
        {{ languageOptions["en"]?.title }}
      </span>
      <i class="flag-icon flag-icon-{{ languageOptions['en']?.flag }}"></i>
    </a>

    <a class="nav-link" *ngIf="_translateService.currentLang === 'en'" (click)="setLanguage('ar')">
      <span class="selected-language mx-25 font-small-2">
        {{ languageOptions["ar"]?.title }}
      </span>
      <i class="flag-icon flag-icon-{{ languageOptions['ar']?.flag }}"></i>
    </a>
  </li>
</ul>