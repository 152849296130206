import { Injectable } from "@angular/core";
import { BreakpointObserver } from "@angular/cdk/layout";
import { BehaviorSubject } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
} from "rxjs/operators";
import { BS_BREAKPOINTS } from "app/layout/custom-breakpoints";

@Injectable({
  providedIn: "root",
})
export class CoreMediaService {
  currentMediaQuery: string;
  onMediaUpdate: BehaviorSubject<string> = new BehaviorSubject<string>("");

  /**
   * Constructor
   *
   * @param {BreakpointObserver} _breakpointObserver
   */
  constructor(private _breakpointObserver: BreakpointObserver) {
    // Set the defaults
    this.currentMediaQuery = "";

    // Initialize
    this._init();
  }

  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Initialize
   *
   * @private
   */
  private _init(): void {
    this._breakpointObserver
      .observe(BS_BREAKPOINTS.map((bp) => bp.mediaQuery))
      .pipe(
        debounceTime(500),
        map((result) => {
          const matchingBreakpoint = BS_BREAKPOINTS.find(
            (bp) => result.breakpoints[bp.mediaQuery]
          );
          return matchingBreakpoint ? matchingBreakpoint.alias : null;
        }),
        distinctUntilChanged(),
        filter((alias) => alias !== null)
      )
      .subscribe((alias) => {
        this.onMediaUpdate.next(alias);
      });
  }
}
