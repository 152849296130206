import { switchMap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { JsonResponse } from 'app/shared/types/response.type';
import { RegisterDto } from './payloads/register.dto';
import { AuthService } from 'app/modules/auth';
import { StartupService } from 'app/layout/services/startup.service';
import { LocalStorageService } from 'app/shared/services/local-storage.service';
import { OnboardingStateService } from './onboarding-state.service';

@Injectable()
export class OnboardingService {
  private readonly API_URL = `${environment.API_URL}/v1/onboardings`;

  constructor(
    private _http: HttpClient,
    private _localStorageService:LocalStorageService,
    private _onboardingStateService:OnboardingStateService,
    private _authService: AuthService,
    private _startupService: StartupService
  ) {}

  register(input: RegisterDto) {
    return this._http
      .post<JsonResponse<{ token: string; businessId: string }>>(
        `${this.API_URL}/signup`,
        input
      )
      .pipe(
        switchMap((res) => {
          this._localStorageService.setItem(
            'active_business',
            res.data.businessId
          );
          this._localStorageService.setItem(environment.OAUTH_PREFIX, {
            token: res.data.token,
          });
          this._authService.authStateValue = { token: res.data.token };
          this._onboardingStateService.onOnboardingStateChange({
            contact: input.contact,
          });

          return this._startupService.resolve();
        })
      );
  }
}
