export const BS_BREAKPOINTS = [
  { alias: "bs-xs", mediaQuery: "(max-width: 575.98px)" },
  {
    alias: "bs-sm",
    mediaQuery: "(min-width: 576px) and (max-width: 767.98px)",
  },
  {
    alias: "bs-md",
    mediaQuery: "(min-width: 768px) and (max-width: 991.98px)",
  },
  {
    alias: "bs-lg",
    mediaQuery: "(min-width: 992px) and (max-width: 1199.98px)",
  },
  { alias: "bs-xl", mediaQuery: "(min-width: 1200px)" },

  { alias: "bs-gt-sm", mediaQuery: "(min-width: 576px)" },
  { alias: "bs-gt-md", mediaQuery: "(min-width: 768px)" },
  { alias: "bs-gt-lg", mediaQuery: "(min-width: 992px)" },
  { alias: "bs-gt-xl", mediaQuery: "(min-width: 1200px)" },

  { alias: "bs-lt-sm", mediaQuery: "(max-width: 575.98px)" },
  { alias: "bs-lt-md", mediaQuery: "(max-width: 767.98px)" },
  { alias: "bs-lt-lg", mediaQuery: "(max-width: 991.98px)" },
  { alias: "bs-lt-xl", mediaQuery: "(max-width: 1199.98px)" },
];
