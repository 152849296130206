import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BusinessService } from 'app/modules/setting/business';
import { noWhitespaceValidator } from 'app/shared/validators/whitespace.validator';

@Component({
  selector: 'create-business-modal',
  templateUrl: './create-business-modal.component.html',
})
export class CreateBusinessModalComponent implements OnInit {
  businessForm: FormGroup;
  isLoading: boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private _businessService: BusinessService
  ) {}

  ngOnInit(): void {
    this.initializeForm();
  }

  createBusiness() {
    this.isLoading = true;

    this._businessService.create({ draft: this.businessForm.value }).subscribe({
      next: ({ data }) => {
        this.isLoading = false;
        this.activeModal.close();
        window.location.href = `business/${data?.id}/get-started`;
      },
    });
  }

  get f() {
    return this.businessForm.controls;
  }

  private initializeForm() {
    this.businessForm = this.fb.group({
      name: [null, [noWhitespaceValidator]],
      name_localized: [null, [noWhitespaceValidator]],
    });
  }
}
