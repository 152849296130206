<form class="form" [formGroup]="signupForm" (ngSubmit)="submit()">
  <div class="row">

    <!-- owner name -->
    <div class="col-12">
      <div class="form-group">
        <label for="owner_name">
          {{ "DATA.LABELS.OWNER_NAME" | translate }}<span>*</span>
        </label>
        <input type="text" id="owner_name" class="form-control" name="owner_name" placeholder="{{ 'DATA.PLACEHOLDERS.OWNER_NAME' | translate }}" formControlName="owner_name"
          [class.error]="f['owner_name'].touched && f['owner_name'].errors" />
        <div class="custom-error" *ngIf="f['owner_name'].touched && f['owner_name'].errors">
          <span *ngIf="f['owner_name'].errors.required">
            {{ "DATA.ERRORS.OWNER_NAME" | translate }}
          </span>
        </div>
      </div>
    </div>

    <!-- business name -->
    <div class="col-12">
      <div class="form-group">
        <label for="business_name">{{ "DATA.LABELS.BUSINESS_NAME" | translate }}<span>*</span></label>
        <input type="text" id="business_name" class="form-control" name="business_name" placeholder="{{ 'DATA.PLACEHOLDERS.BUSINESS_NAME' | translate }}" formControlName="business_name"
          [class.error]="f['business_name'].touched && f['business_name'].errors" />
        <div class="custom-error" *ngIf="f['business_name'].touched && f['business_name'].errors">
          <span *ngIf="f['business_name'].errors.required">
            {{ "DATA.ERRORS.BUSINESS_NAME" | translate }}
          </span>
        </div>
      </div>
    </div>


    <div class="col-12">
      <div class="form-group">
        <label for="wa-number">{{ "DATA.LABELS.WA_NUMBER" | translate }}<span>*</span></label>
        <tel-input formControlName="contact" [invalid]="f.contact.errors && f.contact.touched"></tel-input>
        <div class="custom-error" *ngIf="f['contact'].touched && f['contact'].errors">
          <span *ngIf="f.contact.value && f.contact.errors">
            {{ "DATA.ERRORS.INVALID_WA_NUMBER" | translate }}
          </span>
          <span *ngIf="f['contact'].touched && f['contact'].value == null">
            {{ "DATA.ERRORS.WA_REQUIRED" | translate }}
          </span>
        </div>
      </div>
    </div>

    <div class="col-12">
      <div class="form-group">
        <label for="owner-password">{{ "DATA.LABELS.PASSWORD" | translate }}<span>*</span></label>
        <password-control [control]="f['password']" placeholder="{{ 'DATA.PLACEHOLDERS.PASSWORD' | translate }}" id="owner-password"></password-control>
        <div class="custom-error" *ngIf="f['password'].touched && f['password'].errors">
          <span *ngIf="f['password'].errors.required">
            {{ "DATA.ERRORS.PASSWORD_REQUIRED" | translate }}
          </span>
          <span *ngIf="f['password'].errors.minlength">
            {{ "DATA.ERRORS.PASSWORD_LENGTH" | translate }}
          </span>
        </div>
      </div>
    </div>

    <!-- <p class="font-small-2 text-center w-100">
      {{ "DATA.LABELS.TERMS_AND_CONDITIONS_1" | translate }}
      <a [href]="
          _rtlService.directionState.dir == 'rtl'
            ? 'https://www.gofooder.com/terms-of-service'
            : 'https://www.gofooder.com/terms-of-service-en'
        ">{{ "DATA.LABELS.TERMS_AND_CONDITIONS_2" | translate }}</a>
    </p> -->

    <div class="col-12">
      <button type="submit" class="btn btn-primary btn-block" [disabled]="!signupForm.valid || isLoading" (keydown.enter)="submit()">
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="isLoading"></span>
        <span class="mx-50 align-middle">
          {{ "DATA.BUTTONS.REGISTER_NOW" | translate }}
        </span>
      </button>
    </div>

    <div class="col-12">
      <div class="d-flex align-items-center justify-content-between w-100">
        <p class="my-2 font-small-2 w-100">
          {{ "DATA.LABELS.ALREADY_HAVE" | translate }}
          <a routerLink="/auth/login">{{ "DATA.LABELS.LOGIN" | translate }}</a>
        </p>

        <language-selection></language-selection>
      </div>
    </div>
  </div>
</form>