import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { RegisterDto } from '../../services/payloads/register.dto';
import { phoneValidator } from 'app/shared/validators/phone.validator';
import { RTLService } from 'app/shared/services/rtl.service';

@Component({
  selector: 'signup-form',
  templateUrl: './signup-form.component.html',
})
export class SignupFormComponent implements OnInit {
  signupForm: FormGroup;
  @Input() isLoading: boolean;
  @Output() onSubmit: EventEmitter<RegisterDto>;

  constructor(private fb: FormBuilder, public _rtlService: RTLService) {
    this.onSubmit = new EventEmitter();
  }

  ngOnInit(): void {
    this.signupForm = this.fb.group(
      {
        owner_name: [null, Validators.required],
        password: [null, [Validators.required, Validators.minLength(8)]],
        business_name: [null, Validators.required],
        contact: [null, [Validators.required, phoneValidator]],
      }
    );
  }

  submit() {
    // Extra check for validation
    if (!this.signupForm.valid) {
      this.signupForm.markAllAsTouched();
      return;
    }

    // Don't need to send confirm password key
    const countryCode = localStorage.getItem('country');

    this.onSubmit.emit({ country: countryCode, ...this.signupForm.value});
  }

  get f() {
    return this.signupForm.controls;
  }
}
