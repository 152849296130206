import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CreateQuestionnaireDto } from '../../services/payloads/create-questionnaire.dto';
import { phoneValidator } from '../../../../shared/validators/phone.validator';

@Component({
  selector: 'questionnaire-form',
  templateUrl: './questionnaire-form.component.html',
})
export class QuestionnaireFormComponent implements OnInit {
  questionnaireForm: FormGroup;
  @Output() onSubmit: EventEmitter<CreateQuestionnaireDto>;
  @Input() isLoading: boolean;

  availableBranchesRange = ['1_5', '6_20', 'OVER_20'];
  availableOrdersRange = ['50_100', '100_300', 'OVER_300'];
  availableRestaurantCategory = [
    'GRILLS',
    'SEAFOOD',
    'FAST_FOOD',
    'BAKERY',
    'OTHERS',
  ];
  availableChannels = [
    'FACEBOOK',
    'INSTAGRAM',
    'SNAPCHAT',
    'TIKTOK',
    'EXISTING_SYSTEM',
    'HUNGER_STATION',
    'JAGEZ',
    'MARSOOL',
    'SELLING_OFFLINE',
  ];
  availableSalesFocus = [
    'WHATSAPP_ORDERING',
    'ONLINE_ORDERING',
    'PHONE_ORDERING',
  ];

  constructor(private fb: FormBuilder) {
    this.onSubmit = new EventEmitter<CreateQuestionnaireDto>();
  }

  ngOnInit(): void {
    this.questionnaireForm = this.fb.group({
      branches_range: [null, Validators.required],
      orders_range: [null, Validators.required],
      restaurant_category: [null, Validators.required],
      current_sales_channels: [null, Validators.required],
      sales_focus: [null, Validators.required],
    });
  }

  submit() {
    if (!this.questionnaireForm.valid) {
      this.questionnaireForm.markAllAsTouched();
      return;
    }

    this.onSubmit.emit({
      ...this.questionnaireForm.value,
      current_sales_channels: this.f['current_sales_channels'].value.join(','),
      sales_focus: this.f['sales_focus'].value.join(','),
    });
  }

  get f() {
    return this.questionnaireForm.controls;
  }
}
