import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Business } from '../domain/business';
import { environment } from 'environments/environment';
import { JsonResponse, ListResponse } from 'app/shared/types/response.type';
import {
  CreateBusinessDto,
  GetBusinessDto,
  UpdateBusinessDto,
  UploadBusinessFileDto,
} from './payloads/businessPayload';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class BusinessService {
  private readonly basePath: string = `${environment.API_URL}/v1/businesses`;
  private businessesSubject: BehaviorSubject<Business[]>;
  private currentBusinessSubject: BehaviorSubject<Business>;
  currentBusiness: Observable<Business>;

  constructor(private _http: HttpClient, private _router: Router) {
    // For attach list of user businesses that he have
    this.businessesSubject = new BehaviorSubject(null);
    // For the current business details across the app
    this.currentBusinessSubject = new BehaviorSubject(null);
    this.currentBusiness = this.currentBusinessSubject.asObservable();
  }

  create({ draft }: CreateBusinessDto) {
    return this._http.post<JsonResponse<{ id: string }>>(`${this.basePath}`, {
      ...draft,
    });
  }

  getAll(): Observable<Business[]> {
    return this._http.get<ListResponse<Business>>(`${this.basePath}`).pipe(
      map((response) => {
        this.businessesSubject.next(response.data);
        return response.data;
      })
    );
  }

  findOne({ businessId, query }: GetBusinessDto) {
    return this._http.get<JsonResponse<Business>>(
      `${this.basePath}/${businessId}`,
      {
        params: { ...query },
      }
    );
  }

  update({ businessId, draft }: UpdateBusinessDto) {
    return this._http.put<JsonResponse<Business>>(
      `${this.basePath}/${businessId}`,
      draft
    );
  }

  getProviderTemplates({ businessId }) {
    return this._http.get<JsonResponse<any>>(
      `${this.basePath}/${businessId}/provider/templates`
    );
  }

  uploadPolicyFile({ businessId, type, file }: UploadBusinessFileDto) {
    return this._http.post<JsonResponse<{ url: string }>>(
      `${environment.API_URL}/v1/businesses/${businessId}/upload-file`,
      file,
      {
        params: {
          type,
        },
      }
    );
  }

  // Businesses State
  get businessesValue() {
    return this.businessesSubject.value;
  }

  getActiveBusiness(businessId: string) {
    const business = this.businessesValue?.find(
      (business) => business.id === businessId
    );

    return business;
  }

  emitBusiness(input: Business) {
    const index = this.businessesValue?.findIndex(
      (business) => business.id === input.id
    );
    Object.assign(this.businessesValue[index], input);
  }

  removeBusiness() {
    this.businessesSubject.next(null);
    this.currentBusinessSubject.next(null);
  }

  navigateToGetStarted(businessId: string) {
    this._router.navigate(['business', businessId, 'get-started'], {
      queryParams: { success: 'BUSINESS' },
    });
  }
}
