export const environment = {
  production: true,
  // API_URL: 'http://localhost:8080/api', // Local API,
  API_URL: 'https://api.gofooder.com/api', // Development API
  SOCKET_URL: 'https://api.gofooder.com',
  OAUTH_PREFIX: 'oauth',
  COMMON_360_PARTNER_ID: 'm2R23JPA',
  IP_REGISTER_KEY: 'v82jzjew7s5crzgg',
  FOODER_HELP_CENTER: 'https://help.gofooder.com/portal/{LANG}/kb',
  S3_BUCKET: 'https://static.gofooder.com/assets',
  AVC_CONSOLE: 'https://app.mottasl.ai/login',
  GOOGLE_API_KEY: 'AIzaSyA7PZaiXCLEYv87EUljxGz6B4LvM_gku5o',
};
