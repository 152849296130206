import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { JsonResponse } from '../types/response.type';

@Injectable({ providedIn: 'root' })
export class AsapService {
  constructor(
    private _http: HttpClient,
    @Inject(DOCUMENT) private document: Document
  ) {}

  authorize() {
    return this._http.post<JsonResponse<{ token: string }>>(
      `${environment.API_URL}/v1/asap/authorize`,
      {}
    );
  }

  addASAPScript(jwtToken: string, reference: string) {
    // Create the ZohoHCAsapSettings Script.
    const asapSettingScript = this.document.createElement('script');
    asapSettingScript.textContent = `
      window.ZohoHCAsapSettings = {
        ticketsSettings: {
          preFillFields: {
            customFields: {
              "Fooder Restaurant - RID": {
                "defaultValue": "R${reference}"
              }
            }
          },
        },
        userInfo: {
          jwtToken: "${jwtToken}",
        },
      };
    `;

    const salesIqScript = this.document.createElement('script');
    salesIqScript.textContent = `
      <script type="text/javascript" id="zsiqchat">var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode: "siqb97b1f7c4575d6f658a85fb5b0d83d7e05f1ddd95754facbf773493fceb1cf10", values:{},ready:function(){}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="https://salesiq.zohopublic.com/widget";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);</script>
    `;

    // Create the ASAP widget script.
    const asapWidgetScript = this.document.createElement('script');
    asapWidgetScript.type = 'text/javascript';
    asapWidgetScript.nonce = '{place_your_nonce_value_here}';
    asapWidgetScript.src =
      'https://desk.zoho.com/portal/api/web/inapp/527933000048280041?orgId=723454380';
    asapWidgetScript.defer = true;

    // Append scripts to the document body
    this.document.body.appendChild(asapSettingScript);
    this.document.body.appendChild(asapWidgetScript);
    this.document.body.appendChild(salesIqScript);
  }
}
