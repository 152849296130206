<div class="navbar-container d-flex content">
  <div class="bookmark-wrapper d-flex align-items-center">
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <a class="nav-link menu-toggle" (click)="toggleSidebar('menu')">
          <svg class="icon-secondary icon-md">
            <use xlink:href="assets/sprite.svg#menu"></use>
          </svg>
        </a>
      </li>
    </ul>
  </div>
  <ul class="nav navbar-nav align-items-center ml-auto">
    <!-- Setup Guide -->
    <a
      class="btn btn-icon btn-flat-secondary d-flex align-items-center"
      (click)="navigateToHelpCenter()"
      *ngIf="activeBusiness?.type == 'WA_FORM'"
    >
      <svg class="icon-lg mx-25" style="stroke: none">
        <use xlink:href="assets/sprite.svg#book-open"></use>
      </svg>
      <span>{{ "DATA.LABELS.SETUP_GUIDE" | translate }}</span>
    </a>
    <!--/ Setup Guide -->

    <!-- Language selection -->
    <language-selection></language-selection>
    <!--/ Language selection -->

    <!-- Notification -->
    <app-navbar-notification></app-navbar-notification>
    <!--/ Notification -->

    <!-- User Dropdown -->
    <li ngbDropdown class="nav-item dropdown-user">
      <a
        class="nav-link dropdown-toggle dropdown-user-link"
        ngbDropdownToggle
        id="menu-drop"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <svg class="icon-second" style="width: 25px; height: 25px">
          <use xlink:href="assets/sprite.svg#user-circle"></use>
        </svg>
      </a>
      <div
        ngbDropdownMenu
        aria-labelledby="menu-drop"
        class="dropdown-menu dropdown-menu-right"
      >
        <a
          ngbDropdownItem
          [routerLink]="['./account']"
          class="dropdown-menu__custom"
        >
          <svg class="icon-second icon-lg">
            <use xlink:href="assets/sprite.svg#user"></use>
          </svg>
          <span class="mx-50">{{ "DATA.MENU.ACCOUNT" | translate }}</span></a
        >
        <div class="dropdown-divider"></div>
        <a ngbDropdownItem (click)="logout()" class="logout-color">
          <svg class="icon-danger icon-lg">
            <use xlink:href="assets/sprite.svg#power"></use>
          </svg>
          <span class="mx-50">{{ "DATA.LABELS.LOGOUT" | translate }}</span>
        </a>
      </div>
    </li>
    <!--/ User Dropdown -->
  </ul>
</div>
