<header class="questionnaireHeader">
  <img
    src="{{ coreConfig.app.appLogoImage }}"
    alt="brand-logo"
    class="onboarding__wrapper__img"
  />
</header>

<div class="questionnaireBody">
  <h1 class="font-medium-6 font-weight-bolder">
    {{ "DATA.TITLES.WELCOME_NEW_LIGHT" | translate }}
  </h1>
  <h4 class="font-small-4 font-weight-bolder text-black-50 mb-3">
    {{ "DATA.TITLES.CREATE_BUSINESS" | translate }}
  </h4>

  <questionnaire-form
    (onSubmit)="submit($event)"
    [isLoading]="isLoading"
  ></questionnaire-form>

  <language-selection class="d-block mt-2"></language-selection>
</div>
