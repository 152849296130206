import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CoreMenuService } from '@core/components/core-menu/core-menu.service';
import { User } from 'app/modules/setting/user';
import { ActivatedRoute } from '@angular/router';
import { BusinessService } from 'app/modules/setting/business';
import { BusinessPermissionRouteMap } from '../../../app/modules/setting/business/domain/businessPermissions';
import { CoreMenu } from '../../types';

@Component({
  selector: '[core-menu]',
  templateUrl: './core-menu.component.html',
  styleUrls: ['./core-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoreMenuComponent implements OnInit, OnDestroy {
  currentUser: User;
  businessId: string;

  @Input()
  menu: any;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   *
   * @param {ChangeDetectorRef} _changeDetectorRef
   * @param {CoreMenuService} _coreMenuService
   */
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _coreMenuService: CoreMenuService,
    private _activatedRoute: ActivatedRoute,
    private _businessService: BusinessService
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.businessId = this._activatedRoute.snapshot.paramMap.get('businessId');
    // Set the menu either from the input or from the service
    this.menu = this.menu || this._coreMenuService.getCurrentMenu();

    // Subscribe to the current menu changes
    this._coreMenuService.onMenuChanged
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        this.currentUser = this._coreMenuService.currentUser;
        // Load menu
        this.menu = this._coreMenuService.getCurrentMenu();

        this._changeDetectorRef.markForCheck();
      });
  }

  get activeBusiness() {
    return this._businessService.getActiveBusiness(this.businessId);
  }

  checkPermission(item: CoreMenu) {
    let permission = BusinessPermissionRouteMap[item.url];
    return (
      permission &&
      !this.activeBusiness?.permission?.permissions.includes(permission)
    );
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}
