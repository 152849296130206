<!-- Modal -->
<div class="modal-body p-3" tabindex="0" ngbAutofocus>
  <div class="d-flex flex-column align-items-center">
    <h5
      class="font-medium-1 highlightOrders"
      [innerHTML]="
        'DATA.NOTIFICATIONS.NEW_ORDER' | translate : { count: ordersCount }
      "
    ></h5>
    <h5 class="font-medium-1" *ngIf="type === 'scheduled_order'">
      {{ "DATA.NOTIFICATIONS.SCHEDULED_ORDER" | translate }}
    </h5>
    <h5 class="font-medium-1" *ngIf="type === 'paid_order'">
      {{ "DATA.NOTIFICATIONS.PAID_ORDER" | translate : { uid } }}
    </h5>
  </div>
  <div class="row">
    <div class="col-12 d-flex align-items-center justify-content-center my-1">
      <button
        type="button"
        class="btn btn-block btn-second"
        (click)="activeModal.close('closed')"
      >
        {{ "DATA.BUTTONS.CLOSE" | translate }}
      </button>
    </div>
  </div>
</div>
