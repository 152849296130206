import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { OnboardingState } from '../domain/onboardingState';

@Injectable()
export class OnboardingStateService {
  onboardingState: Observable<OnboardingState>;
  private readonly onboardingStateSubject: BehaviorSubject<OnboardingState>;

  constructor() {
    this.onboardingStateSubject = new BehaviorSubject({
      contact: null,
    });
    this.onboardingState = this.onboardingStateSubject.asObservable();
  }

  get onboardingStateValue() {
    return this.onboardingStateSubject.value;
  }

  onOnboardingStateChange(state: OnboardingState) {
    this.onboardingStateSubject.next(state);
  }
}
