import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GetOrderDto, GetOrdersDto } from './payloads/orderPayload';
import { JsonResponse, ListResponse } from 'app/shared/types/response.type';
import { Order } from '../domain/order';
import { environment } from 'environments/environment';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class OrderService {
  liveOrdersState: Observable<ListResponse<Order>>;
  private liveOrdersStateSubject: BehaviorSubject<ListResponse<Order>>;

  constructor(private _http: HttpClient) {
    this.liveOrdersStateSubject = new BehaviorSubject({ data: [], meta: {} });
    this.liveOrdersState = this.liveOrdersStateSubject.asObservable();
  }

  getOne({ id, query }: GetOrderDto) {
    return this._http.get<JsonResponse<Order>>(
      `${environment.API_URL}/v1/orders/${id}`,
      { params: { ...query } }
    );
  }

  getAll({ query }: GetOrdersDto) {
    return this._http.get<ListResponse<Order>>(
      `${environment.API_URL}/v1/orders`,
      {
        params: { ...query },
      }
    );
  }

  getInvoice({ id, query }: GetOrderDto) {
    return this._http.get(`${environment.API_URL}/v1/orders/${id}/invoice`, {
      params: { ...query },
      responseType: 'text',
    });
  }

  // Live orders behavior subjects
  get liveOrders() {
    return this.liveOrdersStateSubject.value;
  }

  set liveOrders(data: ListResponse<Order>) {
    this.liveOrdersStateSubject.next(data);
  }
}
