import { map } from 'rxjs/operators';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { User } from '../domain/user';
import { JsonResponse, ListResponse } from 'app/shared/types/response.type';
import {
  ChangeAccountPasswordDto,
  PersonalAccountDto,
} from './payloads/account.dto';
import {
  CreateUserDto,
  DeleteUserDto,
  FindUserDto,
  FindUsersDto,
  UpdateUserDto,
} from './payloads/user.dto';
import { Branch } from '../../branch';
import { USER_ROLES } from '../../../../shared/types/roles.type';
import { BusinessType } from '../../business/domain/businessType';

@Injectable({ providedIn: 'root' })
export class UserService {
  private readonly API_URL: string = `${environment.API_URL}/v1/admins`;
  private currentProfileSubject: BehaviorSubject<User>;
  currentProfile: Observable<User>;

  constructor(private _http: HttpClient) {
    this.currentProfileSubject = new BehaviorSubject(null);
    this.currentProfile = this.currentProfileSubject.asObservable();
  }

  getProfile(): Observable<void> {
    return this._http
      .get<JsonResponse<User>>(`${environment.API_URL}/v1/profile`, {
        params: { include: 'organization,roles' },
      })
      .pipe(
        map((response) => {
          this.currentProfileSubject.next(response.data);
        })
      );
  }

  updateProfile(
    input: ChangeAccountPasswordDto | PersonalAccountDto,
    adminId: string
  ): Observable<JsonResponse<User>> {
    return this._http.put<JsonResponse<User>>(
      `${this.API_URL}/${adminId}`,
      input
    );
  }

  create(input: CreateUserDto): Observable<JsonResponse<{ id: string }>> {
    return this._http.post<JsonResponse<{ id: string }>>(
      `${this.API_URL}`,
      input
    );
  }

  update(input: Partial<UpdateUserDto>, userId: string) {
    return this._http.put<JsonResponse<User>>(
      `${this.API_URL}/${userId}`,
      input
    );
  }

  findAll({ query }: FindUsersDto) {
    return this._http.get<ListResponse<User>>(`${this.API_URL}`, {
      params: { ...query },
    });
  }

  findOne({ userId, query }: FindUserDto) {
    return this._http.get<JsonResponse<User>>(`${this.API_URL}/${userId}`, {
      params: { ...query },
    });
  }

  delete({ userId }: DeleteUserDto) {
    return this._http.delete<JsonResponse<null>>(`${this.API_URL}/${userId}`);
  }

  assignedBranches() {
    return this._http.get<JsonResponse<Branch[]>>(
      `${environment.API_URL}/v1/branches`
    );
  }

  // Related with current user state
  emitProfile(input: User) {
    this.currentProfileSubject.next(input);
  }

  get profileValue() {
    return this.currentProfileSubject.value;
  }

  defaultRoute(businessType: BusinessType) {
    if (businessType == 'WA_FORM') return 'my-orders';

    switch (this.profileValue.roles[0]) {
      case USER_ROLES.OWNER:
        return 'orders';
      case USER_ROLES.MANAGER:
        return 'dashboard';
      case USER_ROLES.LEADER:
        return 'orders';
      case USER_ROLES.CASHIER:
        return 'orders';
    }
  }
}
