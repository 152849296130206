import { AbstractControl, ValidationErrors } from '@angular/forms';
import { phones } from '../data/phones';

export function phoneValidator(
  control: AbstractControl
): ValidationErrors | null {
  const value = control.value;

  if (value && !value?.startsWith('+')) {
    return { invalidPhone: true };
  }

  if (value) {
    for (const key in phones) {
      // istanbul ignore else
      if (phones.hasOwnProperty(key)) {
        const phone = phones[key];
        if (phone.test(value)) {
          return null;
        }
      }
    }
    return { invalidPhone: true };
  }

  return null;
}
