import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { VerticalLayoutComponent } from './layout/containers/layout/layout.component';
import { AuthGuard } from './modules/auth';
import { StartupResolver } from './layout/resolvers/startup.resolver';
import { PermissionResolver } from './shared/resolvers/permission.resolver';
import { OnboardingComponent } from './modules/onboarding/containers/onboarding/onboarding.component';
import { OnboardingSignupComponent } from './modules/onboarding/containers/signup/signup.component';
import { OnboardingGuard } from './modules/onboarding/guards/onboarding.guard';
import { OnboardingQuestionnaireComponent } from './modules/onboarding/containers/questionnaire/questionnaire.component';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () =>
      import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'onboarding',
    component: OnboardingComponent,
    children: [{ path: '', component: OnboardingSignupComponent }],
  },
  {
    path: 'onboarding/questionnaire',
    component: OnboardingQuestionnaireComponent,
    canActivate: [OnboardingGuard],
  },
  {
    path: 'redirect',
    loadChildren: () =>
      import('./modules/redirect/redirect.module').then(
        (m) => m.RedirectModule
      ),
  },
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full',
  },
  /**
   * All routes that related to business id
   */
  {
    path: 'business/:businessId',
    component: VerticalLayoutComponent,
    canActivate: [AuthGuard],
    resolve: { startup: StartupResolver },
    children: [
      // Get Started Route
      {
        path: 'get-started',
        loadChildren: () =>
          import('./modules/get-started/get-started.module').then(
            (m) => m.GetStartedModule
          ),
      },
      // Dashboard Route
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./modules/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
      },
      // Analytics Route
      {
        path: 'analytics',
        loadChildren: () =>
          import('./modules/analytics/analytics.module').then(
            (m) => m.AnalyticsWrapperModule
          ),
      },
      // Reports Route
      {
        path: 'reports',
        loadChildren: () =>
          import('./modules/report/report.module').then((m) => m.ReportModule),
      },
      // Orders Route
      {
        path: 'orders',
        loadChildren: () =>
          import('./modules/order/pos/order.module').then((m) => m.OrderModule),
      },
      // Orders Route
      {
        path: 'my-orders',
        loadChildren: () =>
          import('./modules/order/waform/waform-orders.module').then(
            (m) => m.WAFormOrdersModule
          ),
      },
      // Order History Route
      {
        path: 'orders-history',
        loadChildren: () =>
          import('./modules/operation/order-history/order-history.module').then(
            (m) => m.OrderHistoryModule
          ),
      },
      // Pause Branches Route
      {
        path: 'pause',
        loadChildren: () =>
          import('./modules/operation/pause-branch/pause-branch.module').then(
            (m) => m.PauseBranchModule
          ),
      },
      // Menu Operation Routes
      {
        path: 'inventory',
        loadChildren: () =>
          import('./modules/operation/inventory/inventory.module').then(
            (m) => m.InventoryModule
          ),
      },
      // Complains Route
      {
        path: 'complaints',
        loadChildren: () =>
          import('./modules/operation/complaint/complaint.module').then(
            (m) => m.ComplaintModule
          ),
        resolve: { hasPermission: PermissionResolver },
      },
      // Feedback Route
      {
        path: 'feedbacks',
        loadChildren: () =>
          import('./modules/operation/feedback/feedback.module').then(
            (m) => m.FeedbackModule
          ),
      },
      // Review Route
      {
        path: 'reviews',
        loadChildren: () =>
          import('./modules/operation/review/review.module').then(
            (m) => m.ReviewModule
          ),
        resolve: { hasPermission: PermissionResolver },
      },
      // Groups Route
      {
        path: 'groups',
        loadChildren: () =>
          import('./modules/menu/group/group.module').then(
            (m) => m.GroupModule
          ),
      },
      // Categories Route
      {
        path: 'categories',
        loadChildren: () =>
          import('./modules/menu/category/category.module').then(
            (m) => m.CategoryModule
          ),
      },
      // Products Route
      {
        path: 'products',
        loadChildren: () =>
          import('./modules/menu/product/product.module').then(
            (m) => m.ProductModule
          ),
      },
      // Modifiers Route
      {
        path: 'modifiers',
        loadChildren: () =>
          import('./modules/menu/modifier/modifier.module').then(
            (m) => m.ModifierModule
          ),
      },
      // Options Route
      {
        path: 'options',
        loadChildren: () =>
          import('./modules/menu/option/option.module').then(
            (m) => m.OptionModule
          ),
      },
      // Coupons Route
      {
        path: 'discounts',
        loadChildren: () =>
          import('./modules/marketing/coupon/rooming/discount.module').then(
            (m) => m.DiscountModule
          ),
      },
      // Coupons Route
      {
        path: 'coupons',
        loadChildren: () =>
          import('./modules/marketing/coupon/pos/coupon.module').then(
            (m) => m.CouponModule
          ),
      },
      // Sales Channels Route
      {
        path: 'sales-channels',
        loadChildren: () =>
          import('./modules/marketing/sales-channel/sales-channel.module').then(
            (m) => m.SalesChannelModule
          ),
      },
      // QR Code Route
      {
        path: 'qr-code',
        loadChildren: () =>
          import('./modules/marketing/qr-code/qr-code.module').then(
            (m) => m.QRModule
          ),
      },
      // Customers Route
      {
        path: 'customers',
        loadChildren: () =>
          import('./modules/CRM/customer/customer.module').then(
            (m) => m.CustomerModule
          ),
      },
      // Chatbot integration Route
      {
        path: 'chatbot-integration',
        loadChildren: () =>
          import(
            './modules/messaging/messaging-integration/messaging-integrations.module'
          ).then((m) => m.MessagingIntegrationModule),
        resolve: { hasPermission: PermissionResolver },
      },
      // 360 integration Route
      {
        path: 'three-sixty-integration',
        loadChildren: () =>
          import(
            './modules/messaging/chatbot-integration/chatbot-integration.module'
          ).then((m) => m.ChatbotIntegrationModule),
      },
      // WhatsApp Analysis Route
      {
        path: 'wa-analysis',
        loadChildren: () =>
          import('./modules/messaging/wa-analysis/wa-analysis.module').then(
            (m) => m.WAAnalysisModule
          ),
        resolve: { hasPermission: PermissionResolver },
      },
      // Integrations Route
      {
        path: 'integrations',
        loadChildren: () =>
          import('./modules/solution/integration/integration.module').then(
            (m) => m.IntegrationModule
          ),
      },
      // Ordering Channels Route
      {
        path: 'ordering-channels',
        loadChildren: () =>
          import(
            './modules/solution/ordering-channel/ordering-channel.module'
          ).then((m) => m.OrderingChannelModule),
      },
      // Business Settings Route
      {
        path: 'business-settings',
        loadChildren: () =>
          import('./modules/setting/business/pos/business.module').then(
            (m) => m.BusinessModule
          ),
      },
      // store Settings Route
      {
        path: 'store-settings',
        loadChildren: () =>
          import('./modules/setting/business/waform/store.module').then(
            (m) => m.StoreModule
          ),
      },
      {
        path: 'account',
        loadChildren: () =>
          import('./modules/setting/account/account.module').then(
            (m) => m.AccountModule
          ),
      },
      // Users Route
      {
        path: 'users',
        loadChildren: () =>
          import('./modules/setting/user/user.module').then(
            (m) => m.UserModule
          ),
      },
      // Cart Setting Route
      {
        path: 'cart-settings',
        loadChildren: () =>
          import('./modules/setting/cart/cart-setting.module').then(
            (m) => m.CartSettingModule
          ),
      },
      // Branches Route
      {
        path: 'branches',
        loadChildren: () =>
          import('./modules/setting/branch/pos/branch.module').then(
            (m) => m.BranchModule
          ),
      },
      // WA Branches Route
      {
        path: 'waform-branches',
        loadChildren: () =>
          import('./modules/setting/branch/waform/waform-branch.module').then(
            (m) => m.WAFormBranchModule
          ),
      },
      // Drivers Route
      {
        path: 'delivery-management',
        loadChildren: () =>
          import('./modules/setting/driver/driver.module').then(
            (m) => m.DriverModule
          ),
        resolve: { hasPermission: PermissionResolver },
      },
      // System Logs Route
      {
        path: 'system-logs',
        loadChildren: () =>
          import('./modules/setting/system-log/system-log.module').then(
            (m) => m.SystemLogModule
          ),
      },
      // Subscription
      {
        path: 'subscription',
        loadChildren: () =>
          import('./modules/subscription/subscription.module').then(
            (m) => m.SubscriptionModule
          ),
      },
      // Campaigns
      {
        path: 'campaigns',
        loadChildren: () =>
          import('./modules/marketing/campaigns/campaigns.module').then(
            (m) => m.CampaignsModule
          ),
      },
      // Broadcast
      {
        path: 'broadcast',
        loadChildren: () =>
          import('./modules/marketing/broadcast/broadcast.module').then(
            (m) => m.BroadcastModule
          ),
      },
      {
        path: '',
        redirectTo: '',
        pathMatch: 'full',
      },
    ],
  },
  {
    path: 'business',
    redirectTo: '',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
