import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'password-control',
  templateUrl: './password-control.component.html',
  styleUrls: ['./password-control.component.scss'],
})
export class PasswordControlComponent {
  @Input() control: FormControl;
  @Input() id: string;
  @Input() placeholder: string;

  passwordType: boolean = true;
}
