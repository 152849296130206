import {
  ActivatedRouteSnapshot,
  ResolveFn,
  RouterStateSnapshot,
} from '@angular/router';
import { inject } from '@angular/core';
import { of } from 'rxjs';
import { BusinessService } from 'app/modules/setting/business';
import { UserService } from 'app/modules/setting/user';
import { StartupService } from '../services/startup.service';

export const StartupResolver: ResolveFn<void> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const _userService = inject(UserService);
  const _businessService = inject(BusinessService);
  const _startupService = inject(StartupService);

  if (!_userService.profileValue || !_businessService.currentBusiness) {
    return _startupService.resolve();
  }

  return of(null);
};
