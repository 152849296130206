import { Component, ViewEncapsulation } from '@angular/core';
import { ToastrService, ToastPackage, Toast } from 'ngx-toastr';

@Component({
  selector: 'toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ToastComponent extends Toast {
  constructor(toastrService: ToastrService, toastPackage: ToastPackage) {
    super(toastrService, toastPackage);
  }
}
