export const LiteArticles = {
  en: {
    main: 'fooder/fooder-light',
    analytics: 'analytics-28-10-2023',
    qr_code: 'how-to-create-qr-codes-28-10-2023',
    sales_channels: 'how-to-set-up-your-sales-channels-28-10-2023',
    discounts: 'manage-discount',
    ordering_channels: 'ordering-channels-28-10-2023',
    feedbacks: 'customer-feedback-28-10-2023-1',
    groups: 'manage-groups-28-10-2023',
    categories: 'create-new-category-28-10-2023',
    products: 'create-pro',
    modifiers: 'create-new-modifier',
    options: 'create-new-option-28-10-2023',
    store_settings: 'how-to-customize-your-brand-setting-28-10-2023-1',
    account: 'how-to-change-your-account-details-28-10-2023',
    waform_branches: 'create-new-branch-28-10-2023',
    cart_settings: 'how-to-manage-cart-settings-2-1-2024',
    my_orders: 'ho-1-1-2024',
  },

  ar: {
    main: 'فودر/فودر-لايت',
    analytics: 'التحليلات-25-11-2023',
    qr_code: 'كيفية-إنشاء-رمز-الاستجابة-السريعة-23-11-2023',
    sales_channels: 'كيفية-إعداد-قنوات-المبيعات-22-11-2023',
    discounts: 'كيفية-إنشاء-وإدارة-كوبونات-الخصم-23-11-2023',
    ordering_channels: 'كيفية-ترتيب-قنوات-الطلب',
    feedbacks: 'كيفية-إستعراض-ردود-العملاء-المستلمة',
    groups: 'كيفية-إنشاء-مجموعة-لمنيو-الطعام-21-11-2023',
    categories: 'كيفية-إنشاء-وتعديل-الفئات-لقائمة-الطعام-21-11-2023',
    products: 'كيفية-إنشاء-المنتجات-لقائمة-الطعام-21-11-2023 ',
    modifiers: 'كيفية-إنشاء-المعدلات-والخيارات-27-11-2023',
    options: 'كيفية-إنشاء-المعدلات-والخيارات-27-11-2023',
    store_settings: 'كيفية-إضافة-معلومات-عن-المطعم-15-11-2023',
    account: 'فودر/فودر-لايت/إدارة-مطعمك/حسابي',
    waform_branches: 'كيفية-إنشاء-فرع-جديد-16-11-2023',
    cart_settings: 'كيفية-إدارة-إعدادات-السلة',
    my_orders: 'كيفية-إدارة-طلباتي',
  },
};
