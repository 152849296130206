import { CoreMenu } from '@core/types';
import { USER_ROLES } from 'app/shared/types/roles.type';

export const menu: CoreMenu[] = [
  // Get Started
  {
    id: 'getStarted',
    title: 'Get started',
    translate: 'DATA.MENU.GET_STARTED',
    type: 'item',
    icon: 'rocket-launch',
    url: 'get-started',
    role: [USER_ROLES.OWNER, USER_ROLES.MANAGER],
  },
  // Dashboard
  {
    id: 'dashboard',
    title: 'Dashboard',
    translate: 'DATA.MENU.DASHBOARD',
    type: 'item',
    icon: 'dashboard',
    url: 'dashboard',
    role: [USER_ROLES.OWNER],
  },
  {
    id: 'reports',
    title: 'Reports',
    translate: 'DATA.MENU.REPORTS',
    type: 'item',
    icon: 'download',
    url: 'reports',
    role: [USER_ROLES.OWNER],
  },
  {
    id: 'analytics',
    title: 'Analytics',
    translate: 'DATA.MENU.ANALYTICS',
    type: 'item',
    icon: 'bar-chart',
    url: 'analytics',
    role: [USER_ROLES.OWNER],
  },
  // Orders
  {
    id: 'liveOrders',
    type: 'item',
    title: 'Live orders',
    translate: 'DATA.MENU.ORDERS',
    icon: 'reception',
    url: 'orders',
    role: [
      USER_ROLES.OWNER,
      USER_ROLES.MANAGER,
      USER_ROLES.LEADER,
      USER_ROLES.CASHIER,
    ],
  },
  {
    id: 'reviewOrders',
    title: 'Reviews',
    translate: 'DATA.MENU.REVIEWS',
    type: 'item',
    url: 'reviews',
    icon: 'star',
    role: [USER_ROLES.OWNER, USER_ROLES.LEADER, USER_ROLES.MANAGER],
  },
  // Operations
  {
    id: 'operations',
    type: 'collapsible',
    title: 'Operations',
    translate: 'DATA.MENU.OPERATIONS',
    icon: 'layers-three',
    role: [USER_ROLES.OWNER, USER_ROLES.LEADER, USER_ROLES.MANAGER],
    children: [
      {
        id: 'inventory',
        title: 'Inventory',
        translate: 'DATA.MENU.MENU',
        type: 'item',
        url: 'inventory',
      },
      {
        id: 'branchesOperations',
        title: 'Branches operation',
        translate: 'DATA.MENU.BRANCHES',
        type: 'item',
        url: 'pause',
      },
      {
        id: 'orderHistory',
        title: 'Order history',
        translate: 'DATA.MENU.ORDER_HISTORY',
        type: 'item',
        url: 'orders-history',
      },
      {
        id: 'customerTickets',
        title: 'Customer tickets',
        translate: 'DATA.MENU.TICKETS',
        type: 'item',
        url: 'complaints',
      },
    ],
  },
  // Menu
  {
    id: 'menu',
    type: 'collapsible',
    title: 'Menu',
    translate: 'DATA.MENU.MENU',
    icon: 'clipboard',
    role: [USER_ROLES.OWNER, USER_ROLES.MANAGER],
    children: [
      {
        id: 'menuGroups',
        title: 'Menu groups',
        translate: 'DATA.MENU.GROUPS',
        type: 'item',
        url: 'groups',
      },
      {
        id: 'category',
        title: 'Category',
        translate: 'DATA.MENU.CATEGORIES',
        type: 'item',
        url: 'categories',
      },
      {
        id: 'products',
        title: 'Products',
        translate: 'DATA.MENU.PRODUCTS',
        type: 'item',
        url: 'products',
      },
      {
        id: 'modifierList',
        title: 'Modifiers',
        translate: 'DATA.MENU.MODIFIERS',
        type: 'item',
        url: 'modifiers',
      },
      {
        id: 'itemsModifiers',
        title: 'Options',
        translate: 'DATA.MENU.OPTIONS',
        type: 'item',
        url: 'options',
      },
    ],
  },
  // Marketing
  {
    id: 'marketing',
    type: 'collapsible',
    title: 'Marketing',
    translate: 'DATA.MENU.MARKETING',
    icon: 'megaphone',
    role: [USER_ROLES.OWNER, USER_ROLES.MANAGER],
    children: [
      {
        id: 'discountCoupons',
        title: 'Discount coupons',
        translate: 'DATA.MENU.COUPONS',
        type: 'item',
        url: 'coupons',
        role: [USER_ROLES.OWNER, USER_ROLES.MANAGER],
      },
      {
        id: 'qrCode',
        title: 'QR code',
        translate: 'DATA.MENU.QR_CODE',
        type: 'item',
        url: 'qr-code',
        role: [USER_ROLES.OWNER, USER_ROLES.MANAGER],
      },
      {
        id: 'salesChannel',
        title: 'Sales channel',
        translate: 'DATA.MENU.SALES_CHANNELS',
        type: 'item',
        url: 'sales-channels',
        role: [USER_ROLES.OWNER, USER_ROLES.MANAGER],
      },
      {
        id: 'campaigns',
        title: 'Campaigns',
        translate: 'DATA.MENU.CAMPAIGNS',
        type: 'item',
        url: 'campaigns',
        role: [USER_ROLES.OWNER, USER_ROLES.MANAGER],
      },
      {
        id: 'broadcast',
        title: 'Broadcast',
        translate: 'DATA.MENU.BROADCAST',
        type: 'item',
        url: 'broadcast',
        role: [USER_ROLES.OWNER, USER_ROLES.MANAGER],
      },
    ],
  },
  // CRM
  // {
  //   id: 'crm',
  //   type: 'collapsible',
  //   title: 'CRM',
  //   translate: 'DATA.MENU.CRM',
  //   icon: 'user-group',
  //   role: [USER_ROLES.OWNER],
  //   children: [
  //     {
  //       id: 'customers',
  //       title: 'Customers',
  //       translate: 'DATA.MENU.CUSTOMERS',
  //       type: 'item',
  //       url: 'customers',
  //     },
  //   ],
  // },
  // Messaging
  {
    id: 'messaging',
    type: 'collapsible',
    title: 'Messaging',
    translate: 'DATA.MENU.MESSAGING',
    icon: 'chat',
    role: [USER_ROLES.OWNER, USER_ROLES.MANAGER],
    children: [
      {
        id: 'chatbotIntegration',
        title: 'Chatbot integration',
        translate: 'DATA.MENU.CHATBOT_INTEGRATION',
        type: 'item',
        url: 'chatbot-integration',
      },
      {
        id: 'whatsappAnalysis',
        title: 'Whatsapp analysis',
        translate: 'DATA.MENU.WA_ANALYSIS',
        type: 'item',
        url: 'wa-analysis',
      },
    ],
  },
  // Fooder solutions
  {
    id: 'solutions',
    type: 'collapsible',
    title: 'Fooder solutions',
    translate: 'DATA.MENU.SOLUTION',
    icon: 'bulb',
    role: [USER_ROLES.OWNER],
    children: [
      {
        id: 'integrations',
        title: 'integrations',
        translate: 'DATA.MENU.INTEGRATIONS',
        type: 'item',
        url: 'integrations',
      },
    ],
  },
  // Settings
  {
    id: 'settings',
    type: 'collapsible',
    title: 'Settings',
    translate: 'DATA.MENU.SETTINGS',
    icon: 'settings',
    role: [USER_ROLES.OWNER, USER_ROLES.MANAGER],
    children: [
      {
        id: 'business',
        title: 'Business settings',
        translate: 'DATA.MENU.BUSINESS_SETTINGS',
        type: 'item',
        url: 'business-settings',
      },
      {
        id: 'myAccount',
        title: 'My account',
        translate: 'DATA.MENU.ACCOUNT',
        type: 'item',
        url: 'account',
      },
      {
        id: 'users',
        title: 'Users',
        translate: 'DATA.MENU.USERS',
        type: 'item',
        url: 'users',
        role: [USER_ROLES.OWNER],
      },
      {
        id: 'branches',
        title: 'Branches',
        translate: 'DATA.MENU.BRANCHES',
        type: 'item',
        url: 'branches',
      },
      {
        id: 'deliveryManagement',
        title: 'Delivery management',
        translate: 'DATA.MENU.DELIVERY_MANAGEMENT',
        type: 'item',
        url: 'delivery-management',
      },
      {
        id: 'systemLog',
        title: 'System log',
        translate: 'DATA.MENU.SYSTEM_LOGS',
        type: 'item',
        url: 'system-logs',
      },
    ],
  },
];
