import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

const API_URL = `${environment.API_URL}/admin/locale`;

class CustomTranslateHttpLoader extends TranslateHttpLoader {
  override getTranslation(lang: string): Observable<any> {
    return super.getTranslation(`?locale=${lang}`);
  }
}

export function HttpLoaderFactory(http: HttpClient) {
  return new CustomTranslateHttpLoader(http, API_URL);
}
