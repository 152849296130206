<div class="input-password">
  <input
    [type]="passwordType ? 'password' : 'text'"
    [id]="id"
    [name]="id"
    [placeholder]="placeholder"
    [class.error]="control.touched && control.errors"
    class="form-control form-control-text"
    [formControl]="control"
  />

  <a
    href="javascript:void(0);"
    class="input-password__button"
    (click)="passwordType = !passwordType"
  >
    <svg
      *ngIf="passwordType"
      viewBox="0 0 16 11"
      xmlns="http://www.w3.org/2000/svg"
      fill="#D8DFF2"
      style="width: 16px; height: 16px"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16 5.5c0 .917-3.582 5.5-8 5.5S0 6.417 0 5.5C0 4.583 3.582 0 8 0s8 4.583 8 5.5zm-4.5 0a3.5 3.5 0 11-7 0 3.5 3.5 0 017 0zM8 7.5a2 2 0 100-4 2 2 0 000 4z"
      ></path>
    </svg>

    <svg
      *ngIf="!passwordType"
      viewBox="0 0 16 16"
      fill="#D8DFF2"
      xmlns="http://www.w3.org/2000/svg"
      class=""
      style="width: 16px; height: 16px"
    >
      <path
        d="M16 8c-1.167-3.493-4.303-6-8-6a8.012 8.012 0 00-3.685.9L2.414 1 1 2.414 13.586 15 15 13.586l-1.63-1.63A9.026 9.026 0 0016 8zM8 4a4 4 0 014 4c0 .741-.21 1.429-.565 2.02L9.932 8.518A2 2 0 008 6c-.179 0-.35.028-.515.072L5.991 4.577A3.889 3.889 0 018 4zm0 8a4 4 0 01-4-4c0-.356.061-.695.149-1.024L1.913 4.741A9.118 9.118 0 000 8c1.167 3.493 4.303 6 8 6a8.02 8.02 0 002.703-.47l-1.68-1.679A3.962 3.962 0 018 12z"
      ></path>
    </svg>
  </a>
</div>
