import { CoreConfig } from '@core/types';

/**
 * Default App Config
 *
 * ? TIP:
 *
 * Change app config based on your preferences.
 * You can also change them on each component basis. i.e `app/main/pages/authentication/auth-login-v1/auth-login-v1.component.ts`
 *
 * ! IMPORTANT: If the enableLocalStorage option is true then make sure you clear the browser local storage(https://developers.google.com/web/tools/chrome-devtools/storage/localstorage#delete).
 *  ! Otherwise, it will not take the below config changes and use stored config from local storage.
 *
 */

export const coreConfig: CoreConfig = {
  app: {
    appLogoImage: 'assets/images/logo/brand-logo.svg', // App Logo
    appLanguage: 'en', // App Default Language (en, fr, de, pt etc..)
  },
  layout: {
    enableLocalStorage: false,
  },
};
