import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({ providedIn: 'root' })
export class NavigationService {
  private params: object | null = null;

  constructor() {}

  set queryParams(params: object) {
    this.params = params;
  }

  get queryParams() {
    return this.params;
  }

  navigateToHelpCenter(lang: string, article: string) {
    window.open(
      `${environment.FOODER_HELP_CENTER.replace(/\{LANG\}/g, lang)}/${article}`
    );
  }

  navigateToArticle(lang: string, article: string, differentRoute = false) {

    // only for different routes that not contain '/articles'
    if (differentRoute) {
      window.open(
        `${environment.FOODER_HELP_CENTER.replace(
          /\{LANG\}/g,
          lang
        )}/${article}`
      );
      return;
    }

    window.open(
      `${environment.FOODER_HELP_CENTER.replace(
        /\{LANG\}/g,
        lang
      )}/articles/${article}`
    );
  }
}
