import {
  Component,
  OnDestroy,
  OnInit,
  HostBinding,
  HostListener,
  Input,
} from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { CoreConfigService } from '@core/services/config.service';

import { Router } from '@angular/router';
import { AuthService } from 'app/modules/auth';
import { CoreMenuService } from '@core/components/core-menu/core-menu.service';
import { NavigationService } from 'app/shared/services/navigation.service';
import { LiteArticles } from 'app/shared/data/liteArticlesUrl';
import { Business } from 'app/modules/setting/business';

@Component({
  selector: 'navbar',
  templateUrl: './navbar.component.html',
})
export class NavbarComponent implements OnInit, OnDestroy {
  public coreConfig: any;

  @Input() activeBusiness:Business;

  @HostBinding('class.fixed-top')
  public isFixed = false;

  @HostBinding('class.navbar-static-style-on-scroll')
  public windowScrolled = false;

  // Add .navbar-static-style-on-scroll on scroll using HostListener & HostBinding
  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (
      (this.windowScrolled && window.scrollY) ||
      document.documentElement.scrollTop ||
      document.body.scrollTop < 10
    ) {
      this.windowScrolled = false;
    }
  }

  // Private
  private _unsubscribeAll: Subject<any>;

  constructor(
    private _router: Router,
    private _coreConfigService: CoreConfigService,
    private _authService: AuthService,
    private _coreSidebarService: CoreSidebarService,
    public _translateService: TranslateService,
    private _coreMenuService: CoreMenuService,
    private _navigationService: NavigationService
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  toggleSidebar(key): void {
    this._coreSidebarService.getSidebarRegistry(key).toggleOpen();
  }

  logout() {
    this._authService.logout();
    this._router.navigate(['/auth/login']);
    this._coreMenuService.unregister('main');
  }

  ngOnInit(): void {
    // Subscribe to the config changes
    this._coreConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this.coreConfig = config;
        // Fix: for vertical layout if default navbar fixed-top than set isFixed = true
        this.isFixed = true;
      });
  }

  navigateToHelpCenter() {
    const lang: string = this._translateService.currentLang;
    this._navigationService.navigateToHelpCenter(lang, LiteArticles[lang]['main']);
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}
