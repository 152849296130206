import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { CoreConfigService } from '@core/services/config.service';
import { SeoService } from 'app/shared/services/seo.service';
import { CoreConfig } from '@core/types';
import { Subject, takeUntil } from 'rxjs';
import { coreConfig } from 'app/app-config';

@Component({
  selector: 'onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OnboardingComponent implements OnInit, OnDestroy {
  coreConfig: CoreConfig = coreConfig;

  private _unsubscribeAll: Subject<any>;
  constructor(
    private _seoService: SeoService,
  ) {
    this._seoService.setMetaTitle('Onboarding | Fooder');
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void { }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}
