export enum INTEGRATION_TYPES {
  PAYMENT = 'PAYMENT',
  POS = 'POS',
  CHATBOT = 'CHATBOT',
  MESSAGING = 'MESSAGING',
}

export enum INTEGRATION_PROVIDER_NAME {
  MYFATOORAH = 'MYFATOORAH',
  FOODICS = 'FOODICS',
  THREE_SIXTY = 'THREE_SIXTY',
  CATALOG = 'CATALOG',
  AVC = 'AVC',
}

export interface IntegrationRequestQuery {
  integrationType: INTEGRATION_TYPES;
  integrationName?: INTEGRATION_PROVIDER_NAME;
}
