<!-- Modal -->
<div class="modal-header bg-white">
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss('Closed')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" tabindex="0" ngbAutofocus>
  <div class="d-flex flex-column">
    <h2 class="mb-2 font-weight-bolder">
      {{ "DATA.TITLES.CREATE_NEW_BUSINESS" | translate }}
    </h2>
  </div>
  <form
    class="form"
    [formGroup]="businessForm"
    (ngSubmit)="createBusiness()"
    trimOnSubmit
  >
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <label for="name">
            {{ "DATA.LABELS.NAME" | translate }}<span>*</span>
          </label>
          <input
            type="text"
            id="name"
            class="form-control"
            name="name"
            placeholder="{{ 'DATA.PLACEHOLDERS.NAME' | translate }}"
            formControlName="name"
            [class.error]="f['name'].touched && f['name'].errors"
          />
          <div
            class="custom-error"
            *ngIf="f['name'].touched && f['name'].errors"
          >
            <span *ngIf="f['name'].errors.required">
              {{ "DATA.ERRORS.NAME" | translate }}
            </span>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="form-group">
          <label for="name-localized">
            {{ "DATA.LABELS.NAME_LOCALIZED" | translate }}<span>*</span>
          </label>
          <input
            type="text"
            id="name-localized"
            class="form-control"
            name="name-localized"
            placeholder="{{ 'DATA.PLACEHOLDERS.NAME_LOCALIZED' | translate }}"
            formControlName="name_localized"
          />
          <div
            class="custom-error"
            *ngIf="f['name_localized'].touched && f['name_localized'].errors"
          >
            <span *ngIf="f['name_localized'].errors.required">
              {{ "DATA.ERRORS.NAME_LOCALIZED" | translate }}
            </span>
          </div>
        </div>
      </div>
      <div class="col-12 my-2">
        <button
          type="submit"
          class="btn btn-primary"
          (keydown.enter)="createBusiness()"
          [disabled]="!businessForm.valid || isLoading"
        >
          <span
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
            *ngIf="isLoading"
          ></span>
          <span class="align-middle mx-50">
            {{ "DATA.BUTTONS.CREATE" | translate }}
          </span>
        </button>
        <button
          type="button"
          class="btn btn-outline-danger mx-50"
          (click)="activeModal.dismiss('cancelled')"
        >
          {{ "DATA.BUTTONS.CANCEL" | translate }}
        </button>
      </div>
    </div>
  </form>
</div>
