import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { OnboardingStateService } from '../services/onboarding-state.service';

export const OnboardingGuard: CanActivateFn = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): boolean => {
  const onboardingStateService = inject(OnboardingStateService);
  const router = inject(Router);
  const data = onboardingStateService.onboardingStateValue;

  if (!data || !data.contact) {
    router.navigate(['/onboarding']);
    return false;
  }

  return true;
};
