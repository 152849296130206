<div class="d-flex align-items-center" style="margin: 0px 2px">
  <div class="d-flex flex-column">
    <h5 class="toast__title">{{ title }}</h5>
    <p class="toast__message">{{ message }}</p>
  </div>
</div>

<a class="toast__close" (click)="remove()">
  <svg
    width="18px"
    height="18px"
    viewBox="0 0 1024 1024"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M195.2 195.2a64 64 0 0 1 90.496 0L512 421.504 738.304 195.2a64 64 0 0 1 90.496 90.496L602.496 512 828.8 738.304a64 64 0 0 1-90.496 90.496L512 602.496 285.696 828.8a64 64 0 0 1-90.496-90.496L421.504 512 195.2 285.696a64 64 0 0 1 0-90.496z"
    />
  </svg>
</a>
