import {
  Component,
  OnInit,
  ElementRef,
  Input,
  ViewChild,
  forwardRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { Countries } from '../../data/countries';
import { LocationService } from 'app/shared/services/location.service';
import { parsePhoneNumber } from 'libphonenumber-js/mobile';

interface Country {
  name: string;
  code: string;
  dial_code: string;
  placeholder: number;
}

@Component({
  selector: 'tel-input',
  templateUrl: './tel-input.component.html',
  styleUrls: ['./tel-input.component.scss'],
  providers: [
    LocationService,
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => TelInputComponent),
    },
  ],
  host: {
    '(document:click)': 'onClick($event)',
  },
})
export class TelInputComponent implements OnInit, ControlValueAccessor {
  @Input() invalid = false;
  @ViewChild('countriesDivRef') countriesDivRef: ElementRef;

  // event emitters
  @Output() inputEvent = new EventEmitter<string>();
  @Output() focusEvent = new EventEmitter<string>();
  @Output() blurEvent = new EventEmitter();

  preferredCountries: Country[] = [
    {
      name: 'Saudi Arabia (السعودية)',
      code: 'sa',
      dial_code: '+966',
      placeholder: 512345678,
    },
    {
      name: 'United Arab Emirates (الإمارات)',
      code: 'ae',
      dial_code: '+971',
      placeholder: 501234567,
    },
    {
      name: 'Egypt (مصر)',
      code: 'eg',
      dial_code: '+20',
      placeholder: 1001234567,
    },
    {
      name: 'India (भारत)',
      code: 'in',
      dial_code: '+91',
      placeholder: 8123456789,
    },
  ];
  countries: Country[] = Countries;
  filteredCountries: Country[] = [];
  selectedCountry: Country;

  contact: string='';
  searchText: string;

  showCountries: boolean = false;
  isSearching = false;
  isDisabled=false;

  constructor(
    private elRef: ElementRef,
    private _locationService: LocationService
  ) {}

  ngOnInit(): void {
    this.autoSelectUserCountry();
  }

  onChange = (value: string) => {};
  onTouched = () => {};

  writeValue(value: any) {
    if(value){
      this.onChange(value);
      this.onTouched();

      const parsedNumber = parsePhoneNumber(value)
      if(parsedNumber){
        this.selectedCountry = this.countries.find(el=>el.code ==parsedNumber.country.toLocaleLowerCase())
        this.contact = parsedNumber.nationalNumber
      }
    }
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled
  }

  onInputChange() {
    this.onChange(this.selectedCountry.dial_code + this.contact);
    this.inputEvent.emit(this.selectedCountry.dial_code + this.contact)
  }

  autoSelectUserCountry() {
    const countryCode = localStorage.getItem('country');

    if (countryCode) {
      this.selectedCountry = Countries.find(
        (el) => el.code.toLocaleLowerCase() == countryCode.toLocaleLowerCase()
      );
    } else {
      this._locationService.getUserLocationDetails().subscribe({
        next: (res) => {
          const countryCode = res.location.country.code.toLowerCase();
          localStorage.setItem('country', countryCode);
          this.selectedCountry = Countries.find(
            (el) =>
              el.code.toLocaleLowerCase() == countryCode.toLocaleLowerCase()
          );
        },
        error: () => {
          this.selectedCountry = Countries.find(
            (el) => el.code.toLocaleLowerCase() == 'sa'
          );
        },
      });
    }
  }

  onSearch() {
    this.isSearching = this.searchText.length > 0;
    this.filteredCountries = Countries.filter(
      (el) => el.name.toLocaleLowerCase().indexOf(this.searchText) >= 0
    );
  }

  checkInput(event: any) {
    if (event.key == 'Backspace') return;
    if (!/^[\d/+]+$/.test(event.key)) {
      event.preventDefault();
    }
  }

  onSelectCountry(country: Country) {
    this.selectedCountry = country;
    this.toggleCountries();
    this.isSearching = false;
    this.searchText = '';
    this.onChange(country.dial_code + this.contact);
    this.inputEvent.emit(this.selectedCountry.dial_code + this.contact)
  }

  toggleCountries() {
    if(this.isDisabled) return
    this.showCountries = !this.showCountries;
  }

  onBlur(){
    this.onTouched()
    this.blurEvent.emit()
  }

  onFocus(){
    this.focusEvent.emit(this.selectedCountry.dial_code + this.contact)
  }

  onClick(event) {
    if (!this.elRef.nativeElement.contains(event.target))
      this.showCountries = false;
  }
}
