import { Directive, ElementRef, HostListener } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';

@Directive({
  selector: '[trimOnSubmit]',
})
export class FormTrimmingDirective {
  constructor(
    private el: ElementRef,
    private formGroupDirective: FormGroupDirective
  ) {}
  @HostListener('ngSubmit')
  onSubmit() {
    const formGroup = this.formGroupDirective.form;
    Object.keys(formGroup.controls).forEach((controlName) => {
      const control = formGroup.controls[controlName];
      if (typeof control.value === 'string')
        control.setValue(control?.value?.trim());
    });
  }
}
