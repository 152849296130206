import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { environment } from 'environments/environment';
import { AuthService } from '../services/auth.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private _authService: AuthService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const isLoggedIn = this._authService.isAuthenticated;

    const auth = this._authService.authStateValue;

    const isApiUrl = request.url.startsWith(environment.API_URL);

    if (isLoggedIn && isApiUrl && auth?.token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
    }

    return next.handle(request).pipe(shareReplay());
  }
}
