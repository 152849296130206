import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { NavigationService } from '../../../shared/services/navigation.service';
import { LiteArticles } from 'app/shared/data/liteArticlesUrl';
import { Business } from 'app/modules/setting/business';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
})
export class FooterComponent implements OnInit, OnDestroy {
  active_route: string;
  lang: string;
  @Input() activeBusiness :Business

  private _unsubscribeAll: Subject<any>;
  constructor(
    private _router: Router,
    public _translateService: TranslateService,
    private _navigationService: NavigationService
  ) {
    this._unsubscribeAll = new Subject();
    this.lang = this._translateService.currentLang;
  }

  ngOnInit(): void {
    this._router.events.pipe(takeUntil(this._unsubscribeAll)).subscribe((_) => {
      const routeComponent = this._router.url.replace(/-/g, '_').split('/');
      this.active_route = routeComponent[routeComponent.length - 1];
    });

    this._translateService.onLangChange
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: ({ lang }) => {
          this.lang = lang;
        },
      });
  }

  navigateToArticle() {
    if (!LiteArticles[this.lang][this.active_route]) return;

    if (this.active_route == 'account' && this.lang == 'ar') {
      this._navigationService.navigateToArticle(
        this.lang,
        LiteArticles[this.lang][this.active_route],
        true
      );
      return;
    }

    this._navigationService.navigateToArticle(
      this.lang,
      LiteArticles[this.lang][this.active_route]
    );
  }

  hasArticle() {
    return LiteArticles[this.lang][this.active_route];
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}
