import { Component, ViewEncapsulation } from '@angular/core';
import { CreateQuestionnaireDto } from '../../services/payloads/create-questionnaire.dto';
import { Router } from '@angular/router';
import { ToastService } from 'app/shared/services/toast.service';
import { Onboarding } from '../../domain/onboarding';
import { LocalStorageService } from 'app/shared/services/local-storage.service';
import { BusinessService } from 'app/modules/setting/business';
import { CoreConfig } from '@core/types';
import { coreConfig } from 'app/app-config';

@Component({
  selector: 'questionnaire',
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OnboardingQuestionnaireComponent {
  isLoading: boolean = false;
  onboarding: Onboarding;
  coreConfig: CoreConfig = coreConfig;

  constructor(
    private _localStorageService: LocalStorageService,
    private _businessService: BusinessService,
    private _toastService: ToastService,
    private _router: Router
  ) {}

  submit(input: CreateQuestionnaireDto) {
    this.isLoading = true;

    const businessId: string =
      this._localStorageService.getItem('active_business');

    this._businessService
      .update({
        businessId,
        draft: { segmentation: input },
      })
      .subscribe({
        next: (res) => {
          this._router.navigate(['/business', businessId, 'get-started']);
        },
        error: (_) => {
          this.isLoading = false;
          this._toastService.error(
            'DATA.LABELS.ERROR',
            'DATA.ERRORS.NUMBER_ONBOARDED'
          );
        },
      });
  }
}
