<div class="d-flex flex-column">
  <h1 class="onboarding__title text-primary mb-2 text-center">
    {{ "DATA.TITLES.REGISTER_TITLE" | translate }}
  </h1>
  <h4 class="onboarding__title mb-2 text-center">
    {{ "DATA.TITLES.REGISTER_DESCRIPTION" | translate }}
  </h4>
  <signup-form
    [isLoading]="isLoading"
    (onSubmit)="signup($event)"
  ></signup-form>
</div>
