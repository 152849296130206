import { Component } from '@angular/core';
import { RegisterDto } from '../../services/payloads/register.dto';
import { OnboardingStateService } from '../../services/onboarding-state.service';
import { OnboardingService } from '../../services/onboarding.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from 'app/shared/services/toast.service';
import { LocalStorageService } from 'app/shared/services/local-storage.service';
import { environment } from 'environments/environment';
import { AuthService } from 'app/modules/auth';
import { StartupService } from 'app/layout/services/startup.service';

@Component({
  selector: 'signup',
  templateUrl: './signup.component.html',
})
export class OnboardingSignupComponent {
  isLoading: boolean = false;

  constructor(
    private _onboardingStateService: OnboardingStateService,
    private _localStorageService: LocalStorageService,
    private _onboardingService: OnboardingService,
    private _toastService: ToastService,
    private _authService: AuthService,
    private _startupService: StartupService,
    private _activeRoute: ActivatedRoute,
    private _router: Router
  ) {}

  signup(input: RegisterDto) {
    this.isLoading = true;

    this._onboardingService.register(input).subscribe({
      next: (res) => {
        this.isLoading = false;

        this._router.navigate(['questionnaire'], {
          relativeTo: this._activeRoute,
        });
      },
      error: (error) => {
        this.isLoading = false;

        if (error.error.message == 'This number already onboard') {
          this._toastService.error(
            'DATA.LABELS.ERROR',
            'DATA.ERRORS.CONTACT_NUMBER_EXISTS'
          );
          return;
        }

        this._toastService.error('DATA.LABELS.ERROR', 'DATA.LABELS.WRONG');
      },
    });
  }

  private get onboardingState() {
    return this._onboardingStateService.onboardingStateValue;
  }
}
