import { USER_ROLES } from '../../../../shared/types/roles.type';
import { STATUS } from '../../../../shared/types/status.type';
import { Branch } from '../../branch';
import { Organization } from './organization';
import { Role } from './role';

export enum UserRelations {
  ROLES = 'roles',
  ORGANIZATION = 'organization',
  BRANCHES = 'branches',
}

export interface User {
  id: string;
  name: string;
  contact: string;
  is_verified: boolean;
  email: string;
  branches: Branch[];
  organization_id: string;
  organization: Organization;
  status: STATUS;
  roles: USER_ROLES[];
  created_at: string;
  updated_at: string;
  deleted_at: string;
}
