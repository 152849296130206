import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClient } from '@angular/common/http';
import { CoreCommonModule } from '@core/common.module';
import { CoreSidebarModule } from '@core/components';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from '@core/core.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { ToastrModule } from 'ngx-toastr';
import { coreConfig } from './app-config';
import { AppCoreModule } from './core/core.module';
import { ToastComponent } from './shared/components/toast/toast.component';
import { HttpLoaderFactory } from './translate-http-loader';
import { LayoutModule } from './layout/layout.module';
import { SocketIoModule } from 'ngx-socket-io';
import { environment } from 'environments/environment';
import * as Sentry from '@sentry/angular-ivy';
import { Router } from '@angular/router';
import { OnboardingModule } from './modules/onboarding/onboarding.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppCoreModule,
    SocketIoModule.forRoot({
      url: environment.SOCKET_URL,
      options: { path: '', autoConnect: false },
    }),
    ToastrModule.forRoot({
      toastComponent: ToastComponent,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    CoreModule.forRoot(coreConfig),
    CoreCommonModule,
    CoreSidebarModule,
    BsDatepickerModule.forRoot(),
    LayoutModule,
    TimepickerModule.forRoot(),
    OnboardingModule,
  ],
  providers: environment.production
    ? [
        {
          provide: ErrorHandler,
          useValue: Sentry.createErrorHandler({
            showDialog: false,
          }),
        },
        {
          provide: Sentry.TraceService,
          deps: [Router],
        },
        {
          provide: APP_INITIALIZER,
          useFactory: () => () => {},
          deps: [Sentry.TraceService],
          multi: true,
        },
      ]
    : [],
  bootstrap: [AppComponent],
})
export class AppModule {}
