import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgModule } from '@angular/core';
import { PasswordControlComponent } from './components/password-control/password-control.component';
import { CommonModule } from '@angular/common';
import { LanguageSelectionComponent } from './components/language-selection/language-selection.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TelInputComponent } from './components/tel-input/tel-input.component';
import { NegativeInputDirective } from './directives/negative-input.directive';
import { FormTrimmingDirective } from './directives/form-trim.directive';
import { TranslateModule } from '@ngx-translate/core';
import { ActionsBarComponent } from './components/actions-bar/actions-bar.component';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { FiltersBarComponent } from './components/filters-bar/filters-bar.component';
import { CoreSidebarModule } from '@core/components';
import { DeleteModalComponent } from './components/delete-modal/delete-modal.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { DateZoneFormatPipe } from './pipes/date-zone-format.pipe';
import { HttpClientJsonpModule } from '@angular/common/http';
import { TelInputDirective } from './directives/tel-input.directive';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgSelectModule } from '@ng-select/ng-select';
import { NavArrowComponent } from './components/nav-arrow/nav-arrow.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    PasswordControlComponent,
    LanguageSelectionComponent,
    TelInputComponent,
    NegativeInputDirective,
    FormTrimmingDirective,
    ActionsBarComponent,
    LoadingSpinnerComponent,
    FiltersBarComponent,
    DeleteModalComponent,
    PaginationComponent,
    // Pipes
    DateZoneFormatPipe,
    TelInputDirective,
    NavArrowComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgbModule,
    TranslateModule,
    FormsModule,
    CoreSidebarModule,
    HttpClientJsonpModule,
    ScrollingModule,
    FormsModule,
    ReactiveFormsModule,
    BsDatepickerModule,
    NgSelectModule,
    RouterModule,
  ],
  exports: [
    PasswordControlComponent,
    LanguageSelectionComponent,
    TelInputComponent,
    NegativeInputDirective,
    DateZoneFormatPipe,
    FormTrimmingDirective,
    TranslateModule,
    ActionsBarComponent,
    LoadingSpinnerComponent,
    FiltersBarComponent,
    PaginationComponent,
    NgbModule,
    TelInputDirective,
    NavArrowComponent,
  ],
})
export class SharedModule {}
