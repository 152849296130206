import { NgModule } from '@angular/core';
import { CoreCommonModule } from '@core/common.module';
import { OnboardingQuestionnaireComponent } from './containers/questionnaire/questionnaire.component';
import { OnboardingComponent } from './containers/onboarding/onboarding.component';
import { OnboardingSignupComponent } from './containers/signup/signup.component';
import { TranslateModule } from '@ngx-translate/core';
import { QuestionnaireFormComponent } from './components/questionnaire-form/questionnaire-form.component';
import { SignupFormComponent } from './components/signup-form/signup-form.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { SharedModule } from 'app/shared/shared.module';
import { OnboardingService } from './services/onboarding.service';
import { OnboardingStateService } from './services/onboarding-state.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    // Containers
    OnboardingComponent,
    OnboardingQuestionnaireComponent,
    OnboardingSignupComponent,
    // Components
    QuestionnaireFormComponent,
    SignupFormComponent,
  ],
  imports: [
    CoreCommonModule,
    TranslateModule,
    NgOtpInputModule,
    NgSelectModule,
    SharedModule,
    RouterModule,
  ],
  providers: [OnboardingService, OnboardingStateService],
})
export class OnboardingModule {}
