<div class="onboarding">
  <div class="onboarding__form">
    <div class="onboarding__wrapper">
      <a href="javascript:void(0);">
        <img
          src="{{ coreConfig.app.appLogoImage }}"
          alt="brand-logo"
          class="onboarding__wrapper-logo"
        />
      </a>
      <router-outlet></router-outlet>
    </div>
  </div>
  <div class="onboarding__info d-none d-lg-block"></div>
</div>
