import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class ToastService {
  constructor(
    private _toastService: ToastrService,
    private _translateService: TranslateService
  ) {}

  success(title: string, message: string) {
    this._toastService.success(
      this._translateService.instant(message),
      this._translateService.instant(title),
      { toastClass: 'toast__item toast__success', timeOut: 5000 }
    );
  }

  error(title: string, message: string) {
    this._toastService.error(
      this._translateService.instant(message),
      this._translateService.instant(title),
      {
        toastClass: 'toast__item toast__error',
        timeOut: 5000,
      }
    );
  }
}
