import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  Renderer2,
} from '@angular/core';

@Directive({
  selector: '[openDropdown]',
})
export class DropdownDirective {
  @Input('openDropdown') element: ElementRef;

  constructor(private elementRef: ElementRef) {}

  @HostListener('document:click', ['$event.target'])
  onClick(targetElement: any) {
    const isInside = this.elementRef.nativeElement.contains(targetElement);

    if (isInside) {
      this.element.nativeElement.classList.toggle(
        'menu-header__dropdown--open'
      );
      return;
    }

    this.element.nativeElement.classList.remove('menu-header__dropdown--open');
  }
}
