import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ElementRef,
  ViewEncapsulation,
  Renderer2,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { Subject, takeUntil } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { CoreLoadingScreenService } from '@core/services/loading-screen.service';
import { RTLService } from './shared/services/rtl.service';
import { LocalStorageService } from './shared/services/local-storage.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit, OnDestroy {
  coreConfig: any;
  menu: any;

  // Private
  private _unsubscribeAll: Subject<any>;

  constructor(
    @Inject(DOCUMENT) private document: any,
    private _elementRef: ElementRef,
    private _coreLoadingScreenService: CoreLoadingScreenService,
    private _translateService: TranslateService,
    private _rtlService: RTLService,
    private _renderer: Renderer2,
    private _localStorageService: LocalStorageService
  ) {
    // Add languages to the translation service
    this._translateService.addLangs(['en', 'ar']);

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    // Set the current language
    this._translateService.use(
      this._localStorageService.getItem('lang') || 'ar'
    );

    this._rtlService.currentDirection
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: (event) => {
          this._renderer.setAttribute(
            this.document.body,
            'dir',
            `${event.dir}`
          );
        },
      });

    this._elementRef.nativeElement.classList.add(
      'vertical-layout',
      'vertical-menu-modern'
    );
    // Add class based on config options
    this._elementRef.nativeElement.classList.add('navbar-sticky');

    // Skin Class (Adding to body as it requires highest priority)
    this.document.body.classList.add('bordered' + '-layout');
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}
