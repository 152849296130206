<div class="position-relative">
  <div class="phoneInput" [ngClass]="{invalid}" *ngIf="selectedCountry">
    <button
      class="phoneInput-country"
      type="button"
      [ngClass]="{ disabled: isDisabled }"
      (click)="toggleCountries()"
    >
      <img
        class="phoneInput-country_flag"
        alt="country"
        [src]="'./assets/images/countries/' + selectedCountry.code + '.webp'"
      />
      <span class="phoneInput-country_code">{{
        selectedCountry.code | uppercase
      }}</span>
      <div
        class="phoneInput-country_arrow"
        [ngClass]="{ flip: showCountries }"
      ></div>
    </button>

    <div class="phoneInput-number">
      <span
        class="phoneInput-number_dialCode"
        [ngClass]="{ disabled: isDisabled }"
        >{{ selectedCountry.dial_code }}</span
      >
      <input
        class="form-control phoneInput-number_contact"
        type="tel"
        [placeholder]="selectedCountry.placeholder"
        [(ngModel)]="contact"
        [disabled]="isDisabled"
        (input)="onInputChange($event)"
        (focus)="onFocus()"
        (blur)="onBlur()"
        (keypress)="checkInput($event)"
      />
    </div>
  </div>

  <div class="countries" #countriesDivRef *ngIf="showCountries">
    <input
      class="form-control mb-1"
      [placeholder]="'DATA.PLACEHOLDERS.SEARCH' | translate"
      [(ngModel)]="searchText"
      (input)="onSearch()"
    />

    <div class="countries-container">
      <ng-container *ngIf="!isSearching">
        <ng-container *ngFor="let country of preferredCountries">
          <div
            class="countries-item"
            [ngClass]="{
              active: country.dial_code == selectedCountry.dial_code,
            }"
            (click)="onSelectCountry(country)"
          >
            <div class="d-flex align-items-center">
              <img
                alt="country"
                [src]="'./assets/images/countries/' + country.code + '.webp'"
              />
              <span>{{ country.name }}</span>
            </div>
            <span>{{ country.dial_code }}</span>
          </div>
        </ng-container>

        <hr style="margin: 5px 0" />

        <ng-container *ngFor="let country of countries">
          <div
            class="countries-item"
            [ngClass]="{
              active: country.dial_code == selectedCountry.dial_code,
            }"
            (click)="onSelectCountry(country)"
          >
            <div>
              <img
                alt="country"
                [src]="'./assets/images/countries/' + country.code + '.webp'"
              />
              <span>{{ country.name }}</span>
            </div>
            <span>{{ country.dial_code }}</span>
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="isSearching">
        <ng-container *ngFor="let country of filteredCountries">
          <div
            class="countries-item"
            [ngClass]="{
              active: country.dial_code == selectedCountry.dial_code,
            }"
            (click)="onSelectCountry(country)"
          >
            <div>
              <img
                alt="country"
                [src]="'./assets/images/countries/' + country.code + '.webp'"
              />
              <span>{{ country.name }}</span>
            </div>
            <span>{{ country.dial_code }}</span>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
