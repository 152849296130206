import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  ResolveFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { BusinessService } from '../../modules/setting/business';
import { BusinessPermissionRouteMap } from '../../modules/setting/business/domain/businessPermissions';

export const PermissionResolver: ResolveFn<boolean> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const _businessService = inject(BusinessService);
  const router = inject(Router);

  const businessId = route.pathFromRoot[1].paramMap.get('businessId');
  const business = _businessService.getActiveBusiness(businessId);
  const path = route.routeConfig.path;

  if (
    !business?.permission?.permissions?.includes(
      BusinessPermissionRouteMap[path]
    )
  ) {
    router.navigate([`/`]);
    return false;
  }

  return true;
};
