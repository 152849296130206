import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { Subject } from 'rxjs';
import { take, takeUntil, filter } from 'rxjs/operators';

import { CoreMenuService } from '@core/components/core-menu/core-menu.service';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { CoreConfig } from '@core/types';
import { coreConfig } from 'app/app-config';
import { Business } from 'app/modules/setting/business';
import { LocalStorageService } from 'app/shared/services/local-storage.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateBusinessModalComponent } from '../create-business-modal/create-business-modal.component';
import { RTLService } from 'app/shared/services/rtl.service';
import { User } from 'app/modules/setting/user';
import { BusinessPermissionsType } from '../../../modules/setting/business/domain/businessPermissions';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
})
export class MenuComponent implements OnInit, OnDestroy {
  coreConfig: CoreConfig = coreConfig;
  menu: any;
  isScrolled: boolean = false;
  @Input() isCollapsed: boolean;
  @Input() currentUser: User;
  @Input() activeBusiness: Business;
  @Input() businesses: Business[];
  @Output() onCollapse: EventEmitter<boolean>;
  @ViewChild('dropdown', { static: true }) elementRef: ElementRef;

  // Private
  private _unsubscribeAll: Subject<any>;
  constructor(
    private _coreMenuService: CoreMenuService,
    private _coreSidebarService: CoreSidebarService,
    private _localStorageService: LocalStorageService,
    private _modalService: NgbModal,
    private _router: Router,
    private _rtlService: RTLService
  ) {
    this.onCollapse = new EventEmitter();
    this._unsubscribeAll = new Subject();
  }

  @ViewChild('sidebar', { static: false }) sidebarRef?: ElementRef;

  ngOnInit(): void {
    this.isCollapsed =
      this._coreSidebarService.getSidebarRegistry('menu').collapsed;

    // Close the menu on router NavigationEnd (Required for small screen to close the menu on select)
    this._router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe(() => {
        if (this._coreSidebarService.getSidebarRegistry('menu')) {
          this._coreSidebarService.getSidebarRegistry('menu').close();
        }
      });

    // scroll to active on navigation end
    this._router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        take(1)
      )
      .subscribe(() => {
        setTimeout(() => {
          const element = this.sidebarRef.nativeElement.querySelector(
            '.navigation .active'
          );
          if (element) {
            const offsetY = element.offsetTop - 180;
            this.sidebarRef.nativeElement.scrollTo({
              top: offsetY,
              behavior: 'smooth',
            });
          }
        });
      });

    // Get current menu
    this._coreMenuService.onMenuChanged
      .pipe(
        filter((value) => value !== null),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe(() => {
        this.menu = this._coreMenuService.getCurrentMenu();
      });
  }

  /**
   * On Destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // Public Methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * On Sidebar scroll set isScrolled as true
   */
  onSidebarScroll(): void {
    if (this.sidebarRef.nativeElement.scrollTop > 3) {
      this.isScrolled = true;
    } else {
      this.isScrolled = false;
    }
  }

  /**
   * Toggle sidebar expanded status
   */
  toggleSidebar(): void {
    this._coreSidebarService.getSidebarRegistry('menu').toggleOpen();
  }

  /**
   * Toggle sidebar collapsed status
   */
  toggleSidebarCollapsible(): void {
    this.onCollapse.emit();
  }

  switchBusiness(business: Business) {
    this._localStorageService.setItem('active_business', business.id);

    window.location.href = `business/${business?.id}/get-started`;
  }

  createBusiness() {
    const modalRef = this._modalService.open(CreateBusinessModalComponent, {
      centered: true,
      windowClass: 'modal',
      animation: false,
    });

    modalRef.closed.subscribe({});
  }

  checkPermission(business: Business) {
    return business?.permission?.permissions?.includes(
      BusinessPermissionsType.LOGIN_ACCESS
    );
  }

  get currentDir() {
    return this._rtlService.directionState.dir;
  }
}
