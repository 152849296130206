import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'app/shared/services/local-storage.service';
import { RTLService } from 'app/shared/services/rtl.service';

@Component({
  selector: 'language-selection',
  templateUrl: './language-selection.component.html',
})
export class LanguageSelectionComponent {
  languageOptions: any;
  constructor(
    public _translateService: TranslateService,
    private _rtlService: RTLService,
    private _localStorageService: LocalStorageService
  ) {
    this.languageOptions = {
      en: {
        title: 'English',
        flag: 'us',
      },
      ar: {
        title: 'العربية',
        flag: 'sa',
      },
    };
  }

  setLanguage(lang: string) {
    this._rtlService.onChangeDirection({ dir: lang === 'ar' ? 'rtl' : 'ltr' });
    this._translateService.use(lang);
    this._localStorageService.setItem('lang', lang);
  }
}
