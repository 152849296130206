import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LocalStorageService } from './local-storage.service';

interface Direction {
  dir: 'rtl' | 'ltr';
}

@Injectable({
  providedIn: 'root',
})
export class RTLService {
  currentDirection: Observable<Direction>;
  private directionSubject: BehaviorSubject<Direction>;

  constructor(private _localStorageService: LocalStorageService) {
    this.directionSubject = new BehaviorSubject<Direction>({
      dir: this.defaultDirection,
    });

    this.currentDirection = this.directionSubject.asObservable();
  }

  get directionState() {
    return this.directionSubject.value;
  }

  get defaultDirection() {
    let lang = this._localStorageService.getItem('lang');

    if (lang) {
      return lang === 'en' ? 'ltr' : 'rtl';
    }

    return 'rtl';
  }

  onChangeDirection(dir: Direction) {
    this.directionSubject.next(dir);
  }
}
