import { map, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CreatePOSIntegrationDto,
  POSIntegration,
  POSIntegrationActivateDto,
} from './payloads/posIntegrationDto';
import { JsonResponse } from 'app/shared/types/response.type';
import { environment } from 'environments/environment';
import {
  INTEGRATION_PROVIDER_NAME,
  INTEGRATION_TYPES,
  IntegrationRequestQuery,
} from '../../../../shared/types/integration';

@Injectable({ providedIn: 'root' })
export class POSIntegrationService {
  private readonly integrationParams: IntegrationRequestQuery;

  private integrationsSubject: BehaviorSubject<JsonResponse<POSIntegration[]>>;

  constructor(private _http: HttpClient) {
    this.integrationsSubject = new BehaviorSubject(null);
    this.integrationParams = {
      integrationName: INTEGRATION_PROVIDER_NAME.FOODICS,
      integrationType: INTEGRATION_TYPES.POS,
    };
  }

  activate({ businessId, draft }: POSIntegrationActivateDto) {
    return this._http.post<JsonResponse<{ authorize_url: string }>>(
      `${environment.API_URL}/v1/businesses/${businessId}/integrations/pos/authorize`,
      draft,
      { params: { ...this.integrationParams } }
    );
  }

  create({ businessId, draft }: CreatePOSIntegrationDto) {
    return this._http.post<JsonResponse<{ authorize_url: string }>>(
      `${environment.API_URL}/v1/businesses/${businessId}/integrations`,
      draft,
      {
        params: { ...this.integrationParams },
      }
    );
  }

  getAvailableIntegrations(businessId: string) {
    return this._http
      .get<JsonResponse<POSIntegration[]>>(
        `${environment.API_URL}/v1/businesses/${businessId}/integrations/integrations`
      )
      .pipe(
        map(({ data }) => {
          this.integrationsSubject.next({ data });
        })
      );
  }

  set integrations(data: POSIntegration) {
    this.integrationsSubject.value.data.push(data);
    this.integrationsSubject.next(this.integrationsSubject.value);
  }

  // Check if subject contain any pos integration
  get isPOSIntegrated() {
    return this.integrationsSubject.value?.data?.some(
      (item) => item.name.toLowerCase() === 'foodics'
    );
  }

  // Check if subject contain any avocado integration
  get isMessagingIntegrated() {
    return this.integrationsSubject.value?.data?.some(
      (item) => item.name.toLowerCase() === 'avc'
    );
  }

  get messagingIntegrations() {
    return this.integrationsSubject.value?.data.filter(
      (item) => item.type === INTEGRATION_TYPES.MESSAGING
    );
  }
}
