import { Socket } from 'ngx-socket-io';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  Router,
} from '@angular/router';
import { Business, BusinessService } from 'app/modules/setting/business';
import { CoreMenuService } from '@core/components/core-menu/core-menu.service';
import { menu } from 'app/menu/menu';
import { waformMenu } from 'app/menu/waform-menu';
import { BusinessType } from 'app/modules/setting/business/domain/businessType';
import { User, UserService } from 'app/modules/setting/user';
import { OrderService } from 'app/modules/order/pos/services/order.service';
import { Order } from 'app/modules/order/pos/domain/order';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NotificationModalComponent } from 'app/layout/components/notification-modal/notification-modal.component';
import { OrderStatusService } from 'app/modules/order/pos/services/order-status.service';
import { AsapService } from 'app/shared/services/asap.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class VerticalLayoutComponent implements OnInit, OnDestroy {
  isCollapsed: boolean = false;
  currentUser: User;
  businesses: Business[];
  activeBusiness: Business;
  isLoading: boolean = false;
  businessId: string;
  menu: any;
  branches: string[];
  modalRef: NgbModalRef;
  newOrdersCount: number = 0;
  audio: HTMLAudioElement;

  private _unsubscribeAll: Subject<any>;

  constructor(
    private _asapService: AsapService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _businessService: BusinessService,
    private _coreMenuService: CoreMenuService,
    private _userService: UserService,
    private _socket: Socket,
    private _orderService: OrderService,
    private _modalService: NgbModal,
    private _orderStatusService: OrderStatusService
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
    this.audio = new Audio('../../../../assets/order_arrived_bell.mpeg');
  }

  ngOnInit(): void {
    /** Get Active User */
    this.currentUser = this._userService.profileValue;
    this._activatedRoute.paramMap
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: (params) => {
          this.businessId = params.get('businessId');
        },
      });

    this.initializeASAP();

    this.getActiveBusiness();
    this.pageMovementListener();
    this.socketConnection();
    this.getAssignedBranches();
    this.initializeOrders();
    this.listenForSocket();
    this.navigateToStartupModule();
  }

  private initializeASAP() {
    if (!environment.production) return; // run this only for production environment.
    this._asapService
      .authorize()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: ({ data }) => {
          this._asapService.addASAPScript(
            data.token,
            this.activeBusiness.reference
          );
        },
      });
  }

  private getAssignedBranches() {
    this._userService
      .assignedBranches()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: ({ data }) => {
          this.branches = data.map((branch) => branch.id);
        },
      });
  }

  private getActiveBusiness() {
    this.businesses = this._businessService.businessesValue;

    // Filter the active business from this list
    this.activeBusiness = this._businessService.getActiveBusiness(
      this.businessId
    );
    // Get the application main menu
    this.menu =
      this.activeBusiness?.type === BusinessType.POS ? menu : waformMenu;
    // Register the menu to the menu service
    this._coreMenuService.register('main', this.menu);

    // Set the main menu as our current menu
    this._coreMenuService.setCurrentMenu('main');
  }

  private socketConnection() {
    this._socket.connect();
  }

  private listenForSocket() {
    // Listen for creating orders
    this._orderCreationSocket();

    // Listen for payment webhook update
    this._orderPaymentSocket();

    // Listen for order status update (Mainly for POS only)
    this._orderUpdateSocket();

    // Listen for product file export
    this._productFileSocket();
  }

  private pageMovementListener() {
    this._router.events.pipe(takeUntil(this._unsubscribeAll)).subscribe({
      next: (event) => {
        if (event instanceof NavigationStart) {
          this.isLoading = true;
        } else if (event instanceof NavigationEnd) {
          this.isLoading = false;
        }
      },
    });
  }

  private initializeOrders() {
    this._orderService
      .getAll({
        query: {
          include: 'customer',
          'status[]': [
            'pending',
            'accepted',
            'scheduled',
            'ready',
            'draft',
            'being_prepared',
          ],
          limit: 999,
        },
      })
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: ({ data, meta }) => {
          this._orderService.liveOrders = { data, meta };
        },
      });
  }

  private navigateToStartupModule() {
    // check if user access the domain only to redirect him to orders module by counting "/"
    if (window.location.href.split('/').length - 1 == 4) {
      if (this.activeBusiness.type == 'POS')
        this._router.navigate([
          'business',
          this.activeBusiness.id,
          'orders',
          'live',
        ]);
      else
        this._router.navigate([
          'business',
          this.activeBusiness.id,
          'my-orders',
        ]);
    }
  }

  // Listen to order update (Mainly for POS updates)
  private _orderUpdateSocket() {
    this._socket.on('order:updated', (message: string) => {
      const order: Order = JSON.parse(message);

      const isAssigned = this.branches.includes(order.branch_id);

      if (!isAssigned) return null;

      this._orderStatusService.orderStatusSubject.next({
        id: order.id,
        status: order.status,
      });
    });
  }

  // Listen to payment webhook.
  private _orderPaymentSocket() {
    this._socket.on('order:payment_done', (message: string) => {
      const { order, status } = JSON.parse(message);
      const isAssigned = this.branches.includes(order.branch_id);

      if (!isAssigned) return null;

      this._orderStatusService.orderStatusSubject.next({
        id: order.id,
        status,
      });
    });
  }

  // Listen for order creation
  private _orderCreationSocket() {
    this._socket.on('order:created', (message: string) => {
      const order: Order = JSON.parse(message);
      const isAssigned = this.branches.includes(order.branch_id);

      if (!isAssigned) return null;
      this._orderService.liveOrders.data.unshift(order);

      this._orderService.liveOrders = {
        data: this._orderService.liveOrders.data,
        meta: this._orderService.liveOrders.meta,
      };

      this.newOrdersCount += 1;

      this.audio.play();

      if (!this.modalRef?.componentInstance) {
        this.modalRef = this._modalService.open(NotificationModalComponent, {
          centered: true,
          windowClass: 'modal',
          animation: false,
          size: 'sm',
        });
      }

      this.modalRef.componentInstance.type = 'new_order';
      this.modalRef.componentInstance.ordersCount = this.newOrdersCount;

      this.modalRef.result.then((_) => {
        this.audio.pause();
        this.newOrdersCount = 0;
        this._modalService.dismissAll();
      });
    });
  }

  // Listen to deleted orders
  private _orderDeletedSocket() {}

  // Listen for product file export
  private _productFileSocket() {
    this._socket.on('product_file:created', (message: string) => {
      const { fileName, fileBuffer } = JSON.parse(message.toString());

      const byteCharacters = atob(fileBuffer);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);

      const blob = new Blob([byteArray], { type: 'text/csv' });

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.style.display = 'none';
      a.href = url;
      a.download = fileName;

      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    });
  }

  collapseMenu() {
    this.isCollapsed = !this.isCollapsed;
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
    // This mean if user logged out, socket will discount from receiving events.
    this._socket.removeAllListeners();
    this._socket.disconnect();
    // Unsubscribe from orders, make array empty
    this._orderService.liveOrders.data = [];
  }
}
