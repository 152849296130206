import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ipRegisterResponse } from '../types/ipRegisterResponse.type';
import { environment } from 'environments/environment';

@Injectable()
export class LocationService {
  constructor(private _http: HttpClient) {}

  getUserLocationDetails() {
    return this._http.get<ipRegisterResponse>(`https://api.ipregistry.co`, {
      params: { key: environment.IP_REGISTER_KEY },
    });
  }
}
