import { ORDER_TYPES } from '../../../../shared/types/orderingService.type';
import { PaymentMethods } from '../../branch/domain/branchPaymentMethod';

export enum BusinessPermissionsType {
  // ORDER PERMISSIONS
  DELIVERY_ORDERS = 'delivery_orders',
  PICK_UP_ORDERS = 'pick_up_orders',
  DINE_IN_ORDERS = 'dine_in_orders',
  DRIVE_THRU_ORDERS = 'drive_thru_orders',
  CALL_CENTER_ORDERS = 'call_center_orders',
  STORE_FRONT_ORDERS = 'storefront_orders',
  PAYMENT_ORDERS = 'payment_orders',
  ASAP_ORDERS = 'asap_orders',
  SCHEDULE_ORDERS = 'schedule_orders',

  // PAYMENT PERMISSIONS
  CASH_PAYMENT = 'cash_payment',
  MY_FATOORAH_PAYMENT = 'my_fatoorah_payment',

  // AGGREGATOR PERMISSIONS
  OTO_AGGREGATOR = 'oto_aggregator',
  OWN_FLEET_AGGREGATOR = 'own_fleet_aggregator',

  // INTEGRATION PERMISSIONS
  FODDICS_INTEGRATION = 'foodics_integration',
  URBAN_PIPER_INTEGRATION = 'urban_piper_integration',

  // ACCESS PERMISSIONS
  LOGIN_ACCESS = 'login_access',
  CHATBOT_ACCESS = 'chatbot_access',
  CUSTOMER_TICKET_ACCESS = 'customer_ticket_access',
  REVIEW_ORDER_ACCESS = 'review_order_access',
}

export const BusinessPermissionsMap = {
  // ORDERING SERVICE
  [ORDER_TYPES.DELIVERY]: BusinessPermissionsType.DELIVERY_ORDERS,
  [ORDER_TYPES.PICK_UP]: BusinessPermissionsType.PICK_UP_ORDERS,
  [ORDER_TYPES.DINE_IN]: BusinessPermissionsType.DINE_IN_ORDERS,
  [ORDER_TYPES.DRIVE_THRU]: BusinessPermissionsType.DRIVE_THRU_ORDERS,

  // PAYMENT METHOD
  [PaymentMethods.CASH]: BusinessPermissionsType.CASH_PAYMENT,
  [PaymentMethods.ONLINE]: BusinessPermissionsType.MY_FATOORAH_PAYMENT,

  // SCHEDULE ORDERS
  asap_orders: BusinessPermissionsType.ASAP_ORDERS,
  pre_orders: BusinessPermissionsType.SCHEDULE_ORDERS,

  // AGGREGATOR ACCESS
  own_fleet: BusinessPermissionsType.OWN_FLEET_AGGREGATOR,
  third_party_fleet: BusinessPermissionsType.OWN_FLEET_AGGREGATOR,
};

export const BusinessPermissionRouteMap = {
  'chatbot-integration': BusinessPermissionsType.CHATBOT_ACCESS,
  'wa-analysis': BusinessPermissionsType.CHATBOT_ACCESS,
  broadcast: BusinessPermissionsType.CHATBOT_ACCESS,
  complaints: BusinessPermissionsType.CUSTOMER_TICKET_ACCESS,
  reviews: BusinessPermissionsType.REVIEW_ORDER_ACCESS,
  'delivery-management': BusinessPermissionsType.OWN_FLEET_AGGREGATOR,
};

export interface BusinessPermission {
  permissions: BusinessPermissionsType[];
  branch_limit: number;
}
