<footer
  class="d-flex align-items-center justify-content-center"
  *ngIf="hasArticle() && activeBusiness?.type == 'WA_FORM'"
>
  <svg class="icon-md icon-primary mx-25">
    <use xlink:href="assets/sprite.svg#bulb"></use>
  </svg>
  <span class="mx-25">{{ "DATA.LABELS.LEARN_MORE" | translate }}</span>
  <span class="text-primary p-0 cursor-pointer" (click)="navigateToArticle()">{{
    "DATA.MENU." + active_route | uppercase | translate
  }}</span>
  <svg class="icon-md icon-primary mx-25">
    <use xlink:href="assets/sprite.svg#external-link"></use>
  </svg>
</footer>
