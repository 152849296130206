import { NgModule } from '@angular/core';

import { MenuComponent } from './components/menu/menu.component';
import { NavbarNotificationComponent } from './components/navbar/navbar-notification/navbar-notification.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { RouterModule } from '@angular/router';
import { CoreCommonModule } from '@core/common.module';
import { CoreSidebarModule, CoreMenuModule } from '@core/components';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from 'app/shared/shared.module';
import { VerticalLayoutComponent } from './containers/layout/layout.component';
import { DropdownDirective } from './directives/dropdown.directive';
import { CreateBusinessModalComponent } from './components/create-business-modal/create-business-modal.component';
import { NotificationModalComponent } from './components/notification-modal/notification-modal.component';
import { FooterComponent } from './components/footer/footer.component';

@NgModule({
  declarations: [
    VerticalLayoutComponent,
    MenuComponent,
    NavbarComponent,
    NavbarNotificationComponent,
    CreateBusinessModalComponent,
    FooterComponent,
    // Directives
    DropdownDirective,
    NotificationModalComponent,
  ],
  imports: [
    RouterModule,
    CoreCommonModule,
    CoreSidebarModule,
    NgbModule,
    CoreMenuModule,
    SharedModule,
  ],
  exports: [VerticalLayoutComponent],
})
export class LayoutModule {}
