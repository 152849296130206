<!-- Menu header -->
<div class="navbar-header">
  <div class="d-flex align-items-center justify-content-between">
    <a>
      <img
        src="{{ coreConfig.app.appLogoImage }}"
        alt="brand-logo"
        width="120"
        class="brandLogo"
      />
    </a>
    <a
      class="nav-link modern-nav-toggle d-none d-xl-block"
      (click)="toggleSidebarCollapsible()"
    >
      <svg [style.width]="20" [style.height]="20" [style.stroke]="'#4279ee'">
        <use
          attr.xlink:href="{{
            'assets/sprite.svg#' + (isCollapsed ? 'circle' : 'disc')
          }}"
        ></use>
      </svg>
    </a>
    <a class="d-block d-xl-none pr-0" (click)="toggleSidebar()">
      <svg [style.width]="20" [style.height]="20" [style.stroke]="'#4279ee'">
        <use xlink:href="assets/sprite.svg#close"></use>
      </svg>
    </a>
  </div>
</div>
<!--/ Menu header -->

<!-- Navbar shadow -->
<div class="shadow-bottom" [ngClass]="{ 'd-block': isScrolled }"></div>

<!-- Main menu -->
<div
  class="main-menu-content"
  (scroll)="onSidebarScroll()"
  #sidebar
  style="overflow-y: scroll; position: relative"
>
  <div class="menu-header">
    <button class="menu-header__info" [openDropdown]="elementRef">
      <span class="menu-header__icon">
        <img
          [src]="
            activeBusiness?.logo ||
            'assets/images/backgrounds/logo-placeholder.svg'
          "
          width="48"
          height="48"
          class="menu-header__img"
        />
      </span>
      <div class="menu-header__text">
        <span>{{
          (currentDir === "ltr"
            ? activeBusiness?.name
            : activeBusiness?.name_localized
          )?.length > 17
            ? ((currentDir === "ltr"
                ? activeBusiness?.name
                : activeBusiness?.name_localized
              ) | slice : 0 : 17) + "..."
            : currentDir === "ltr"
            ? activeBusiness?.name
            : activeBusiness?.name_localized
        }}</span>
        <span class="font-weight-normal font-small-1">
          {{ "DATA.LABELS.BUSINESS_ID" | translate }}:
          <strong> B{{ activeBusiness?.reference }} </strong>
        </span>
      </div>

      <svg class="icon-md icon-secondary">
        <use xlink:href="assets/sprite.svg#arrow-down"></use>
      </svg>
    </button>
    <a
      href="https://{{
        activeBusiness?.domain?.custom_domain ||
          activeBusiness?.domain?.order_domain
      }}"
      target="_blank"
    >
      <div class="menu-header__domain">
        <div class="menu-header__domain-link">
          {{
            activeBusiness?.domain?.custom_domain ||
              activeBusiness?.domain?.order_domain
          }}
        </div>
        <svg class="icon-lg icon-second">
          <use xlink:href="assets/sprite.svg#external-link"></use>
        </svg>
      </div>
    </a>
  </div>
  <div class="menu-header__dropdown" #dropdown>
    <div
      class="d-flex align-items-center justify-content-between font-small-1 px-75"
    >
      <div class="d-flex align-items-center">
        <div class="badge badge-light-secondary">
          {{ "DATA.LABELS.PLATINUM" | translate }}
        </div>
      </div>
      <div
        class="d-flex align-items-center"
        *ngIf="currentUser?.roles[0] === 'owner'"
      >
        <a (click)="createBusiness()" *ngIf="activeBusiness?.type === 'POS'">
          <svg class="icon-lg icon-second mx-50">
            <use xlink:href="assets/sprite.svg#add"></use>
          </svg>
        </a>
        <a
          [routerLink]="
            activeBusiness?.type === 'POS'
              ? ['business-settings']
              : ['store-settings']
          "
        >
          <svg class="icon-lg icon-second">
            <use xlink:href="assets/sprite.svg#settings"></use>
          </svg>
        </a>
      </div>
    </div>
    <div class="menu-header__list">
      <a
        class="menu-header__link mt-50"
        *ngFor="let business of businesses"
        (click)="switchBusiness(business)"
      >
        <button
          class="menu-header__info"
          [ngClass]="{ 'menu-header__info_block': !checkPermission(business) }"
          [disabled]="!checkPermission(business)"
        >
          <div class="menu-header__text">
            <span>{{
              currentDir === "ltr" ? business?.name : business?.name_localized
            }}</span>
            <span class="font-weight-normal font-small-1">
              {{
                business?.domain?.custom_domain ||
                  business?.domain?.order_domain
              }}
            </span>
          </div>

          <svg
            class="icon-lg icon-second"
            *ngIf="business?.id === activeBusiness?.id"
          >
            <use xlink:href="assets/sprite.svg#check"></use>
          </svg>
        </button>
      </a>
    </div>
  </div>
  <ul class="navigation navigation-main" core-menu></ul>
</div>
<!--/ Main menu -->
