<!-- NAVBAR -->
<ng-container *ngTemplateOutlet="navbar"></ng-container>
<!--/ NAVBAR -->

<!-- MENU -->
<ng-container *ngTemplateOutlet="menu"></ng-container>
<!--/ MENU -->

<!-- APP-CONTENT -->
<div class="app-content content">
  <div class="content-overlay"></div>
  <div class="header-navbar-shadow"></div>
  <!-- CONTENT -->
  <ng-container *ngIf="!isLoading; else loading">
    <router-outlet #outlet="outlet"></router-outlet>
  </ng-container>

  <!--/ CONTENT -->
</div>
<!--/ APP-CONTENT -->

<!-- NAVBAR -->
<ng-template #navbar>
  <navbar
    class="header-navbar navbar navbar-expand-lg align-items-center navbar-shadow navbar-light fixed-top"
    [activeBusiness]="activeBusiness"
  >
  </navbar>
</ng-template>
<!--/ NAVBAR -->

<!-- MENU -->
<ng-template #menu>
  <core-sidebar
    name="menu"
    [collapsed]="isCollapsed"
    collapsibleSidebar="bs-gt-xl"
    class="main-menu menu-fixed menu-accordio menu-shadow menu-light"
    overlayClass="sidenav-overlay"
    [ngClass]="[isCollapsed ? '' : 'expanded']"
  >
    <app-menu
      class="vertical-menu"
      [isCollapsed]="isCollapsed"
      [businesses]="businesses"
      [activeBusiness]="activeBusiness"
      [currentUser]="currentUser"
      (onCollapse)="collapseMenu()"
    ></app-menu>
  </core-sidebar>
</ng-template>
<!--/ MENU -->

<!-- FOOTER -->
<app-footer class="footer" [activeBusiness]="activeBusiness"></app-footer>
<!--/ FOOTER -->

<!-- Loading Spinner While Navigation Start -->
<ng-template #loading>
  <div class="loading-zone">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</ng-template>
