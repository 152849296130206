<form [formGroup]="questionnaireForm">
  <div class="form-group mb-2">
    <label
      >{{ "DATA.LABELS.QUESTIONNAIRE1" | translate }}<span>*</span></label
    >
    <div
      class="form-check d-flex align-items-center p-0"
      *ngFor="let range of availableBranchesRange"
    >
      <input
        type="radio"
        formControlName="branches_range"
        name="branches_range"
        [id]="range"
        [value]="range"
        class="questionnaireInput"
      />
      <label
        [attr.for]="range"
        class="form-check-label cursor-pointer mb-25"
        style="padding-inline-start: 20px"
        >{{ "DATA.LABELS." + range + "_BRANCHES" | translate }}</label
      >
    </div>
    <div
    class="custom-error"
    *ngIf="f.branches_range.touched && f.branches_range.errors"
  >
    <span *ngIf="f.branches_range.errors.required">
      {{ "DATA.ERRORS.BRANCHES_RANGE" | translate }}
    </span>
  </div>
  </div>

  <div class="form-group mb-2">
    <label
      >{{ "DATA.LABELS.QUESTIONNAIRE2" | translate }}<span>*</span></label
    >
    <div
      class="form-check d-flex align-items-center p-0"
      *ngFor="let range of availableOrdersRange"
    >
      <input
        type="radio"
        formControlName="orders_range"
        name="orders_range"
        [id]="range"
        [value]="range"
        class="questionnaireInput"
      />
      <label
        [attr.for]="range"
        class="form-check-label cursor-pointer mb-25"
        style="padding-inline-start: 20px"
        >{{ "DATA.LABELS." + range + "_ORDERS" | translate }}</label
      >
    </div>
    <div
    class="custom-error"
    *ngIf="f.orders_range.touched && f.orders_range.errors"
  >
    <span *ngIf="f.orders_range.errors.required">
      {{ "DATA.ERRORS.ORDERS_RANGE" | translate }}
    </span>
  </div>
  </div>

  <div class="form-group mb-2">
    <label
      >{{ "DATA.LABELS.QUESTIONNAIRE3" | translate }}<span>*</span></label
    >
    <ng-select
      [items]="availableRestaurantCategory"
      formControlName="restaurant_category"
      [class.error]="
        f.restaurant_category.touched && f.restaurant_category.errors
      "
    >
      <ng-template ng-option-tmp let-item="item">
        <div class="d-flex align-items-center">
          {{ "DATA.LABELS." + item | translate }}
        </div>
      </ng-template>
      <ng-template ng-label-tmp let-item="item">
        {{ "DATA.LABELS." + item | translate }}
      </ng-template>
    </ng-select>
    <div
      class="custom-error"
      *ngIf="f.restaurant_category.touched && f.restaurant_category.errors"
    >
      <span *ngIf="f.restaurant_category.errors.required">
        {{ "DATA.ERRORS.RESTAURANT_CATEGORY" | translate }}
      </span>
    </div>
  </div>

  <div class="form-group mb-2">
    <label
      >{{ "DATA.LABELS.QUESTIONNAIRE4" | translate }}<span>*</span></label
    >
    <ng-select
      [items]="availableChannels"
      formControlName="current_sales_channels"
      multiple="true"
      [class.error]="
        f.current_sales_channels.touched && f.current_sales_channels.errors
      "
    >
      <ng-template ng-option-tmp let-item="item">
        <div class="d-flex align-items-center">
          {{ "DATA.LABELS." + item | translate }}
        </div>
      </ng-template>
      <ng-template ng-label-tmp let-item="item">
        {{ "DATA.LABELS." + item | translate }}
      </ng-template>
    </ng-select>
    <div
      class="custom-error"
      *ngIf="
        f.current_sales_channels.touched && f.current_sales_channels.errors
      "
    >
      <span *ngIf="f.current_sales_channels.errors.required">
        {{ "DATA.ERRORS.SALES_CHANNELS" | translate }}
      </span>
    </div>
  </div>

  <div class="form-group mb-2">
    <label
      >{{ "DATA.LABELS.QUESTIONNAIRE5" | translate }}<span>*</span></label
    >
    <ng-select
      [items]="availableSalesFocus"
      formControlName="sales_focus"
      multiple="true"
      [class.error]="
        f.sales_focus.touched && f.sales_focus.errors
      "
    >
      <ng-template ng-option-tmp let-item="item">
        <div class="d-flex align-items-center">
          {{ "DATA.LABELS." + item | translate }}
        </div>
      </ng-template>
      <ng-template ng-label-tmp let-item="item">
        {{ "DATA.LABELS." + item | translate }}
      </ng-template>
    </ng-select>
    <div
      class="custom-error"
      *ngIf="
        f.sales_focus.touched && f.sales_focus.errors
      "
    >
      <span *ngIf="f.sales_focus.errors.required">
        {{ "DATA.ERRORS.SALES_FOCUS" | translate }}
      </span>
    </div>
  </div>

  <button class="btn btn-primary d-block m-auto" (click)="submit()">
    {{ "DATA.BUTTONS.SUBMIT" | translate }}
  </button>
</form>