<ng-container *ngFor="let item of menu">
  <!-- item -->
  <li
    core-menu-vertical-item
    *ngIf="
      item.type == 'item' &&
      (item.role
        ? item.role.includes(currentUser.roles[0])
        : false || item.role == undefined)
    "
    [item]="item"
    [businessId]="businessId"
    [ngClass]="{ disabled: item.disabled || checkPermission(item) }"
    [routerLinkActive]="!item.openInNewTab ? 'active' : ''"
    [routerLinkActiveOptions]="{ exact: item.exactMatch || false }"
  >
    <span
      [routerLink]="
        item.openInNewTab ? [] : ['/business', businessId, item.url]
      "
      class="d-none"
    ></span>
  </li>
  <!-- collapsible -->
  <li
    core-menu-vertical-collapsible
    *ngIf="
      item.type == 'collapsible' &&
      (item.role
        ? item.role.includes(currentUser.roles[0])
        : false || item.role == undefined)
    "
    [item]="item"
    [businessId]="businessId"
    [activeBusiness]="activeBusiness"
    class="nav-item has-sub"
  ></li>
</ng-container>
