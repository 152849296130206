import { CoreMenu } from '@core/types';
import { USER_ROLES } from 'app/shared/types/roles.type';

export const waformMenu: CoreMenu[] = [
  // Get Started
  {
    id: 'get-started',
    title: 'Get Started',
    translate: 'DATA.MENU.GET_STARTED',
    type: 'item',
    icon: 'rocket-launch',
    url: 'get-started',
    role: [USER_ROLES.OWNER],
  },
  // Analytics
  {
    id: 'waform-analytics',
    title: 'Analytics',
    translate: 'DATA.MENU.ANALYTICS',
    type: 'item',
    icon: 'bar-chart',
    url: 'analytics',
    role: [USER_ROLES.OWNER],
  },
  // Orders
  {
    id: 'myOrders',
    type: 'item',
    title: 'My orders',
    translate: 'DATA.MENU.MY_ORDERS',
    icon: 'reception',
    url: 'my-orders',
    role: [USER_ROLES.OWNER],
  },
  // Menu
  {
    id: 'menu',
    type: 'collapsible',
    title: 'Menu',
    translate: 'DATA.MENU.MENU',
    icon: 'clipboard',
    role: [USER_ROLES.OWNER],
    children: [
      {
        id: 'menuGroups',
        title: 'Menu groups',
        translate: 'DATA.MENU.GROUPS',
        type: 'item',
        url: 'groups',
      },
      {
        id: 'category',
        title: 'Category',
        translate: 'DATA.MENU.CATEGORIES',
        type: 'item',
        url: 'categories',
      },
      {
        id: 'products',
        title: 'Products',
        translate: 'DATA.MENU.PRODUCTS',
        type: 'item',
        url: 'products',
      },
      {
        id: 'modifierList',
        title: 'Modifiers',
        translate: 'DATA.MENU.MODIFIERS',
        type: 'item',
        url: 'modifiers',
      },
      {
        id: 'itemsModifiers',
        title: 'Options',
        translate: 'DATA.MENU.OPTIONS',
        type: 'item',
        url: 'options',
      },
    ],
  },
  // QR Code
  {
    id: 'qrCode',
    title: 'QR code',
    translate: 'DATA.MENU.QR_CODE',
    type: 'item',
    icon: 'scan',
    url: 'qr-code',
    role: [USER_ROLES.OWNER],
  },
  // Ordering Channels
  {
    id: 'orderingChannels',
    title: 'Ordering channels',
    translate: 'DATA.MENU.ORDERING_CHANNELS',
    type: 'item',
    icon: 'store',
    url: 'ordering-channels',
    role: [USER_ROLES.OWNER],
  },
  // Discount Coupons
  {
    id: 'discountCoupons',
    title: 'Discount coupons',
    translate: 'DATA.MENU.COUPONS',
    type: 'item',
    icon: 'offer',
    url: 'discounts',
    role: [USER_ROLES.OWNER],
  },
  // Sales Channels
  {
    id: 'salesChannel',
    title: 'Sales channel',
    translate: 'DATA.MENU.SALES_CHANNELS',
    type: 'item',
    icon: 'rss-feed',
    url: 'sales-channels',
    role: [USER_ROLES.OWNER],
  },
  // Feedback
  {
    id: 'feedbacks',
    title: 'Customer feedbacks',
    translate: 'DATA.MENU.FEEDBACKS',
    type: 'item',
    icon: 'chatting',
    url: 'feedbacks',
    role: [USER_ROLES.OWNER],
  },
  // Settings
  {
    id: 'settings',
    type: 'collapsible',
    title: 'Settings',
    translate: 'DATA.MENU.SETTINGS',
    icon: 'settings',
    role: [USER_ROLES.OWNER],
    children: [
      {
        id: 'store',
        title: 'Business settings',
        translate: 'DATA.MENU.STORE_SETTINGS',
        type: 'item',
        url: 'store-settings',
      },
      {
        id: 'myAccount',
        title: 'My account',
        translate: 'DATA.MENU.ACCOUNT',
        type: 'item',
        url: 'account',
      },
      {
        id: 'cartSettings',
        title: 'Ordering Cart',
        translate: 'DATA.MENU.CART_SETTINGS',
        type: 'item',
        url: 'cart-settings',
      },
      {
        id: 'branches',
        title: 'Branches',
        translate: 'DATA.MENU.BRANCHES',
        type: 'item',
        url: 'waform-branches',
      },
    ],
  },
  // Subscription
  {
    id: 'subscription',
    title: 'Subscription',
    type: 'item',
    translate: 'DATA.MENU.SUBSCRIPTION',
    icon: 'credit-card',
    url: 'subscription',
    role: [USER_ROLES.OWNER],
  },
];
