import { HttpClient } from '@angular/common/http';
import { switchMap } from 'rxjs/operators';
import { Observable, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { LoginDto } from './payloads/login.dto';
import { JsonResponse } from 'app/shared/types/response.type';
import { LoginResponse } from '../interfaces';
import { LocalStorageService } from 'app/shared/services/local-storage.service';
import { StartupService } from 'app/layout/services/startup.service';
import { BusinessService } from 'app/modules/setting/business';
import {
  ForgotPasswordDto,
  RecoverPasswordDto,
  VerifyPasswordOtpDto,
} from './payloads/forgot-password.dto';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private readonly API_URI = `${environment.API_URL}/v1/auth`;
  authState: Observable<LoginResponse>;
  private authStateSubject: BehaviorSubject<LoginResponse>;
  constructor(
    private _http: HttpClient,
    private _localStorageService: LocalStorageService,
    private _startupService: StartupService,
    private businessService: BusinessService
  ) {
    this.authStateSubject = new BehaviorSubject(
      this._localStorageService.getItem(environment.OAUTH_PREFIX)
    );
    this.authState = this.authStateSubject.asObservable();
  }

  login(input: LoginDto): Observable<void> {
    return this._http
      .post<JsonResponse<LoginResponse>>(`${this.API_URI}/login`, input)
      .pipe(
        switchMap((response) => {
          const { data } = response;
          this._localStorageService.setItem(environment.OAUTH_PREFIX, data);
          // Notify
          this.authStateSubject.next(data);
          return this._startupService.resolve();
        })
      );
  }

  forgotPassword(input: ForgotPasswordDto) {
    return this._http.post<JsonResponse<{ ttl: number; message?: string }>>(
      `${this.API_URI}/forgot-password`,
      input
    );
  }

  verifyOTP(input: VerifyPasswordOtpDto): Observable<void> {
    return this._http.post<void>(`${this.API_URI}/otp`, input);
  }

  resetPassword(input: RecoverPasswordDto): Observable<void> {
    return this._http
      .post<JsonResponse<LoginResponse>>(
        `${this.API_URI}/reset-password`,
        input
      )
      .pipe(
        switchMap((response) => {
          const { data } = response;
          this._localStorageService.setItem(environment.OAUTH_PREFIX, data);
          // Notify
          this.authStateSubject.next(data);
          return this._startupService.resolve();
        })
      );
  }

  logout() {
    this._localStorageService.removeItem(environment.OAUTH_PREFIX);
    this.authStateSubject.next(null);
    this.businessService.removeBusiness();
  }

  get isAuthenticated() {
    const data = this.authStateSubject.value;
    const isAuth = data?.token ? true : false;
    return isAuth;
  }

  set authStateValue(data: LoginResponse) {
    this.authStateSubject.next(data);
  }

  get authStateValue() {
    return this.authStateSubject.value;
  }
}
