import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { OrderStatus } from '../domain/order';
import { AcceptOrderDto } from './payloads/orderPayload';

@Injectable({ providedIn: 'root' })
export class OrderStatusService {
  public orderStatus: Observable<{ id: string; status: OrderStatus }>;
  public orderStatusSubject: BehaviorSubject<{
    id: string;
    status: OrderStatus;
  }>;

  constructor(private _http: HttpClient) {
    this.orderStatusSubject = new BehaviorSubject<{
      id: string;
      status: OrderStatus;
    }>(null);
    this.orderStatus = this.orderStatusSubject.asObservable();
  }

  accept({ id, draft }: AcceptOrderDto) {
    return this._http.put(
      `${environment.API_URL}/v1/orders/${id}/accept`,
      draft
    );
  }

  reject(id: string, reason: string) {
    return this._http.put(`${environment.API_URL}/v1/orders/${id}/reject`, {
      reason,
    });
  }

  markReady(id: string) {
    return this._http.put(
      `${environment.API_URL}/v1/orders/${id}/mark-ready`,
      {}
    );
  }

  complete(id: string) {
    return this._http.put(
      `${environment.API_URL}/v1/orders/${id}/complete`,
      {}
    );
  }

  schedule(id: string) {
    return this._http.put(
      `${environment.API_URL}/v1/orders/${id}/schedule`,
      {}
    );
  }

  prepare(id: string) {
    return this._http.put(`${environment.API_URL}/v1/orders/${id}/prepare`, {});
  }

  assign({ id, draft }: AcceptOrderDto) {
    return this._http.put(
      `${environment.API_URL}/v1/orders/${id}/assign-drivers`,
      draft
    );
  }
}
