import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'notification-modal',
  templateUrl: './notification-modal.component.html',
})
export class NotificationModalComponent {
  @Input() type: 'scheduled_order' | 'new_order' | 'paid_order';
  @Input() uid: string;
  @Input() ordersCount: number;
  constructor(public activeModal: NgbActiveModal) {}
}
