import { filter, switchMap, take } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { Business, BusinessService } from 'app/modules/setting/business';
import { UserService } from 'app/modules/setting/user';
import { LocalStorageService } from 'app/shared/services/local-storage.service';
import { POSIntegrationService } from 'app/modules/solution/integration/services/posIntegration.service';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from '../../modules/auth';

@Injectable({ providedIn: 'root' })
export class StartupService {
  private _authService: AuthService;

  constructor(
    private _userService: UserService,
    private _businessService: BusinessService,
    private _localStorageService: LocalStorageService,
    private _posIntegrationService: POSIntegrationService,
    private _router: Router,
    private injector: Injector
  ) {
    setTimeout(() => {
      this._authService = this.injector.get(AuthService);
    });
  }

  resolve() {
    return this._userService.getProfile().pipe(
      switchMap((_) => {
        return this._businessService.getAll();
      }),
      switchMap((data) => {
        // Verify business id from url
        this._router.events
          .pipe(
            filter((event) => event instanceof NavigationEnd),
            take(1)
          )
          .subscribe(() => this.verifyBusinessInUrl(data));

        let businessId = this._localStorageService.getItem('active_business');

        const included = data.some((business) => business.id === businessId);

        if (!businessId || !included) {
          const business = data[data.length - 1];
          this._localStorageService.setItem('active_business', business.id);
          businessId = business.id;
        }

        return this._posIntegrationService.getAvailableIntegrations(businessId);
      })
    );
  }

  private verifyBusinessInUrl(businesses: Business[]) {
    const regex = /\/business\/([^\/]+)/;
    const match = this._router.url.match(regex);
    if (match) {
      const businessIdFromUrl = match[1];
      const isValidBusiness = businesses.some(
        (business) => business.id === businessIdFromUrl
      );

      if (!isValidBusiness) {
        this._authService.logout();
      }
    }
  }
}
