export enum PaymentMethods {
  CASH = 'CASH',
  CARD = 'CARD',
  ONLINE = 'ONLINE',
  APPLE_PAY = 'APPLE_PAY',
}

export interface BranchPaymentMethod {
  slug: PaymentMethods;
  is_active: boolean;
}
