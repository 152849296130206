export const Countries = [
  {
    name: 'Afghanistan (‫افغانستان‬‎)',
    code: 'af',
    dial_code: '+93',
    placeholder: 701234567,
  },
  {
    name: 'Albania (Shqipëri)',
    code: 'al',
    dial_code: '+355',
    placeholder: 672123456,
  },
  {
    name: 'Algeria (‫الجزائر‬‎)',
    code: 'dz',
    dial_code: '+213',
    placeholder: 551234567,
  },
  {
    name: 'American Samoa',
    code: 'as',
    dial_code: '+1',
    placeholder: 6847331234,
  },
  {
    name: 'Andorra',
    code: 'ad',
    dial_code: '+376',
    placeholder: 312345,
  },
  {
    name: 'Angola',
    code: 'ao',
    dial_code: '+244',
    placeholder: 923123456,
  },
  {
    name: 'Anguilla',
    code: 'ai',
    dial_code: '+1',
    placeholder: 2642351234,
  },
  {
    name: 'Antigua and Barbuda',
    code: 'ag',
    dial_code: '+1',
    placeholder: 2684641234,
  },
  {
    name: 'Argentina',
    code: 'ar',
    dial_code: '+54',
    placeholder: 91123456789,
  },
  {
    name: 'Armenia (Հայաստան)',
    code: 'am',
    dial_code: '+374',
    placeholder: 77123456,
  },
  {
    name: 'Aruba',
    code: 'aw',
    dial_code: '+297',
    placeholder: 5601234,
  },
  {
    name: 'Australia',
    code: 'au',
    dial_code: '+61',
    placeholder: 412345678,
  },
  {
    name: 'Austria (Österreich)',
    code: 'at',
    dial_code: '+43',
    placeholder: 664123456,
  },
  {
    name: 'Azerbaijan (Azərbaycan)',
    code: 'az',
    dial_code: '+994',
    placeholder: 401234567,
  },
  {
    name: 'Bahamas',
    code: 'bs',
    dial_code: '+1',
    placeholder: 2423591234,
  },
  {
    name: 'Bahrain (‫البحرين‬‎)',
    code: 'bh',
    dial_code: '+973',
    placeholder: 36001234,
  },
  {
    name: 'Bangladesh (বাংলাদেশ)',
    code: 'bd',
    dial_code: '+880',
    placeholder: 1812345678,
  },
  {
    name: 'Barbados',
    code: 'bb',
    dial_code: '+1',
    placeholder: 2462501234,
  },
  {
    name: 'Belarus (Беларусь)',
    code: 'by',
    dial_code: '+375',
    placeholder: 294911911,
  },
  {
    name: 'Belgium (België)',
    code: 'be',
    dial_code: '+32',
    placeholder: 470123456,
  },
  {
    name: 'Belize',
    code: 'bz',
    dial_code: '+501',
    placeholder: 6221234,
  },
  {
    name: 'Benin (Bénin)',
    code: 'bj',
    dial_code: '+229',
    placeholder: 90011234,
  },
  {
    name: 'Bermuda',
    code: 'bm',
    dial_code: '+1',
    placeholder: 4413701234,
  },
  {
    name: 'Bhutan (འབྲུག)',
    code: 'bt',
    dial_code: '+975',
    placeholder: 17123456,
  },
  {
    name: 'Bolivia',
    code: 'bo',
    dial_code: '+591',
    placeholder: 71234567,
  },
  {
    name: 'Bosnia and Herzegovina (Босна и Херцеговина)',
    code: 'ba',
    dial_code: '+387',
    placeholder: 61123456,
  },
  {
    name: 'Botswana',
    code: 'bw',
    dial_code: '+267',
    placeholder: 71123456,
  },
  {
    name: 'Brazil (Brasil)',
    code: 'br',
    dial_code: '+55',
    placeholder: 11961234567,
  },
  {
    name: 'British Indian Ocean Territory',
    code: 'io',
    dial_code: '+246',
    placeholder: 3801234,
  },
  {
    name: 'British Virgin Islands',
    code: 'vg',
    dial_code: '+1',
    placeholder: 2843001234,
  },
  {
    name: 'Brunei',
    code: 'bn',
    dial_code: '+673',
    placeholder: 7123456,
  },
  {
    name: 'Bulgaria (България)',
    code: 'bg',
    dial_code: '+359',
    placeholder: 43012345,
  },
  {
    name: 'Burkina Faso',
    code: 'bf',
    dial_code: '+226',
    placeholder: 70123456,
  },
  {
    name: 'Burundi (Uburundi)',
    code: 'bi',
    dial_code: '+257',
    placeholder: 79561234,
  },
  {
    name: 'Cambodia (កម្ពុជា)',
    code: 'kh',
    dial_code: '+855',
    placeholder: 91234567,
  },
  {
    name: 'Cameroon (Cameroun)',
    code: 'cm',
    dial_code: '+237',
    placeholder: 671234567,
  },
  {
    name: 'Canada',
    code: 'ca',
    dial_code: '+1',
    placeholder: 5062345678,
  },
  {
    name: 'Cape Verde (Kabu Verdi)',
    code: 'cv',
    dial_code: '+238',
    placeholder: 9911234,
  },
  {
    name: 'Caribbean Netherlands',
    code: 'bq',
    dial_code: '+599',
    placeholder: 3181234,
  },
  {
    name: 'Cayman Islands',
    code: 'ky',
    dial_code: '+1',
    placeholder: 3453231234,
  },
  {
    name: 'Central African Republic (République centrafricaine)',
    code: 'cf',
    dial_code: '+236',
    placeholder: 70012345,
  },
  {
    name: 'Chad (Tchad)',
    code: 'td',
    dial_code: '+235',
    placeholder: 63012345,
  },
  {
    name: 'Chile',
    code: 'cl',
    dial_code: '+56',
    placeholder: 221234567,
  },
  {
    name: 'China (中国)',
    code: 'cn',
    dial_code: '+86',
    placeholder: 13123456789,
  },
  {
    name: 'Christmas Island',
    code: 'cx',
    dial_code: '+61',
    placeholder: 412345678,
  },
  {
    name: 'Cocos (Keeling) Islands',
    code: 'cc',
    dial_code: '+61',
    placeholder: 412345678,
  },
  {
    name: 'Colombia',
    code: 'co',
    dial_code: '+57',
    placeholder: 3211234567,
  },
  {
    name: 'Comoros (‫جزر القمر‬‎)',
    code: 'km',
    dial_code: '+269',
    placeholder: 3212345,
  },
  {
    name: 'Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)',
    code: 'cd',
    dial_code: '+243',
    placeholder: 991234567,
  },
  {
    name: 'Congo (Republic) (Congo-Brazzaville)',
    code: 'cg',
    dial_code: '+242',
    placeholder: 61234567,
  },
  {
    name: 'Cook Islands',
    code: 'ck',
    dial_code: '+682',
    placeholder: 71234,
  },
  {
    name: 'Costa Rica',
    code: 'cr',
    dial_code: '+506',
    placeholder: 83123456,
  },
  {
    name: 'Côte d’Ivoire',
    code: 'ci',
    dial_code: '+225',
    placeholder: 123456789,
  },
  {
    name: 'Croatia (Hrvatska)',
    code: 'hr',
    dial_code: '+385',
    placeholder: 921234567,
  },
  {
    name: 'Cuba',
    code: 'cu',
    dial_code: '+53',
    placeholder: 51234567,
  },
  {
    name: 'Curaçao',
    code: 'cw',
    dial_code: '+599',
    placeholder: 95181234,
  },
  {
    name: 'Cyprus (Κύπρος)',
    code: 'cy',
    dial_code: '+357',
    placeholder: 96123456,
  },
  {
    name: 'Czech Republic (Česká republika)',
    code: 'cz',
    dial_code: '+420',
    placeholder: 601123456,
  },
  {
    name: 'Denmark (Danmark)',
    code: 'dk',
    dial_code: '+45',
    placeholder: 32123456,
  },
  {
    name: 'Djibouti',
    code: 'dj',
    dial_code: '+253',
    placeholder: 77831001,
  },
  {
    name: 'Dominica',
    code: 'dm',
    dial_code: '+1',
    placeholder: 7672251234,
  },
  {
    name: 'Dominican Republic (República Dominicana)',
    code: 'do',
    dial_code: '+1',
    placeholder: 8092345678,
  },
  {
    name: 'Ecuador',
    code: 'ec',
    dial_code: '+593',
    placeholder: 991234567,
  },
  {
    name: 'Egypt (‫مصر‬‎)',
    code: 'eg',
    dial_code: '+20',
    placeholder: 1001234567,
  },
  {
    name: 'El Salvador',
    code: 'sv',
    dial_code: '+503',
    placeholder: 70123456,
  },
  {
    name: 'Equatorial Guinea (Guinea Ecuatorial)',
    code: 'gq',
    dial_code: '+240',
    placeholder: 222123456,
  },
  {
    name: 'Eritrea',
    code: 'er',
    dial_code: '+291',
    placeholder: 7123456,
  },
  {
    name: 'Estonia (Eesti)',
    code: 'ee',
    dial_code: '+372',
    placeholder: 51234567,
  },
  {
    name: 'Eswatini',
    code: 'sz',
    dial_code: '+268',
    placeholder: 76123456,
  },
  {
    name: 'Ethiopia',
    code: 'et',
    dial_code: '+251',
    placeholder: 911234567,
  },
  {
    name: 'Falkland Islands (Islas Malvinas)',
    code: 'fk',
    dial_code: '+500',
    placeholder: 51234,
  },
  {
    name: 'Faroe Islands (Føroyar)',
    code: 'fo',
    dial_code: '+298',
    placeholder: 211234,
  },
  {
    name: 'Fiji',
    code: 'fj',
    dial_code: '+679',
    placeholder: 7012345,
  },
  {
    name: 'Finland (Suomi)',
    code: 'fi',
    dial_code: '+358',
    placeholder: 412345678,
  },
  {
    name: 'France',
    code: 'fr',
    dial_code: '+33',
    placeholder: 612345678,
  },
  {
    name: 'French Guiana (Guyane française)',
    code: 'gf',
    dial_code: '+594',
    placeholder: 694201234,
  },
  {
    name: 'French Polynesia (Polynésie française)',
    code: 'pf',
    dial_code: '+689',
    placeholder: 87123456,
  },
  {
    name: 'Gabon',
    code: 'ga',
    dial_code: '+241',
    placeholder: 6031234,
  },
  {
    name: 'Gambia',
    code: 'gm',
    dial_code: '+220',
    placeholder: 3012345,
  },
  {
    name: 'Georgia (საქართველო)',
    code: 'ge',
    dial_code: '+995',
    placeholder: 555123456,
  },
  {
    name: 'Germany (Deutschland)',
    code: 'de',
    dial_code: '+49',
    placeholder: 15123456789,
  },
  {
    name: 'Ghana (Gaana)',
    code: 'gh',
    dial_code: '+233',
    placeholder: 231234567,
  },
  {
    name: 'Gibraltar',
    code: 'gi',
    dial_code: '+350',
    placeholder: 57123456,
  },
  {
    name: 'Greece (Ελλάδα)',
    code: 'gr',
    dial_code: '+30',
    placeholder: 6912345678,
  },
  {
    name: 'Greenland (Kalaallit Nunaat)',
    code: 'gl',
    dial_code: '+299',
    placeholder: 221234,
  },
  {
    name: 'Grenada',
    code: 'gd',
    dial_code: '+1',
    placeholder: 4734031234,
  },
  {
    name: 'Guadeloupe',
    code: 'gp',
    dial_code: '+590',
    placeholder: 690001234,
  },
  {
    name: 'Guam',
    code: 'gu',
    dial_code: '+1',
    placeholder: 6713001234,
  },
  {
    name: 'Guatemala',
    code: 'gt',
    dial_code: '+502',
    placeholder: 51234567,
  },
  {
    name: 'Guernsey',
    code: 'gg',
    dial_code: '+44',
    placeholder: 7781123456,
  },
  {
    name: 'Guinea (Guinée)',
    code: 'gn',
    dial_code: '+224',
    placeholder: 601123456,
  },
  {
    name: 'Guinea-Bissau (Guiné Bissau)',
    code: 'gw',
    dial_code: '+245',
    placeholder: 955012345,
  },
  {
    name: 'Guyana',
    code: 'gy',
    dial_code: '+592',
    placeholder: 6091234,
  },
  {
    name: 'Haiti',
    code: 'ht',
    dial_code: '+509',
    placeholder: 34101234,
  },
  {
    name: 'Honduras',
    code: 'hn',
    dial_code: '+504',
    placeholder: 91234567,
  },
  {
    name: 'Hong Kong (香港)',
    code: 'hk',
    dial_code: '+852',
    placeholder: 51234567,
  },
  {
    name: 'Hungary (Magyarország)',
    code: 'hu',
    dial_code: '+36',
    placeholder: 201234567,
  },
  {
    name: 'Iceland (Ísland)',
    code: 'is',
    dial_code: '+354',
    placeholder: 6111234,
  },
  {
    name: 'India (भारत)',
    code: 'in',
    dial_code: '+91',
    placeholder: 8123456789,
  },
  {
    name: 'Indonesia',
    code: 'id',
    dial_code: '+62',
    placeholder: 812345678,
  },
  {
    name: 'Iran (‫ایران‬‎)',
    code: 'ir',
    dial_code: '+98',
    placeholder: 9123456789,
  },
  {
    name: 'Iraq (‫العراق‬‎)',
    code: 'iq',
    dial_code: '+964',
    placeholder: 7912345678,
  },
  {
    name: 'Ireland',
    code: 'ie',
    dial_code: '+353',
    placeholder: 850123456,
  },
  {
    name: 'Isle of Man',
    code: 'im',
    dial_code: '+44',
    placeholder: 7924123456,
  },
  {
    name: 'Italy (Italia)',
    code: 'it',
    dial_code: '+39',
    placeholder: 3123456789,
  },
  {
    name: 'Jamaica',
    code: 'jm',
    dial_code: '+1',
    placeholder: 8762101234,
  },
  {
    name: 'Japan (日本)',
    code: 'jp',
    dial_code: '+81',
    placeholder: 9012345678,
  },
  {
    name: 'Jersey',
    code: 'je',
    dial_code: '+44',
    placeholder: 7797712345,
  },
  {
    name: 'Jordan (‫الأردن‬‎)',
    code: 'jo',
    dial_code: '+962',
    placeholder: 790123456,
  },
  {
    name: 'Kazakhstan (Казахстан)',
    code: 'kz',
    dial_code: '+7',
    placeholder: 7710009998,
  },
  {
    name: 'Kenya',
    code: 'ke',
    dial_code: '+254',
    placeholder: 712123456,
  },
  {
    name: 'Kiribati',
    code: 'ki',
    dial_code: '+686',
    placeholder: 72001234,
  },
  {
    name: 'Kosovo',
    code: 'xk',
    dial_code: '+383',
    placeholder: 43201234,
  },
  {
    name: 'Kuwait (‫الكويت‬‎)',
    code: 'kw',
    dial_code: '+965',
    placeholder: 50012345,
  },
  {
    name: 'Kyrgyzstan (Кыргызстан)',
    code: 'kg',
    dial_code: '+996',
    placeholder: 700123456,
  },
  {
    name: 'Laos (ລາວ)',
    code: 'la',
    dial_code: '+856',
    placeholder: 2023123456,
  },
  {
    name: 'Latvia (Latvija)',
    code: 'lv',
    dial_code: '+371',
    placeholder: 21234567,
  },
  {
    name: 'Lebanon (‫لبنان‬‎)',
    code: 'lb',
    dial_code: '+961',
    placeholder: 71123456,
  },
  {
    name: 'Lesotho',
    code: 'ls',
    dial_code: '+266',
    placeholder: 50123456,
  },
  {
    name: 'Liberia',
    code: 'lr',
    dial_code: '+231',
    placeholder: 770123456,
  },
  {
    name: 'Libya (‫ليبيا‬‎)',
    code: 'ly',
    dial_code: '+218',
    placeholder: 912345678,
  },
  {
    name: 'Liechtenstein',
    code: 'li',
    dial_code: '+423',
    placeholder: 660234567,
  },
  {
    name: 'Lithuania (Lietuva)',
    code: 'lt',
    dial_code: '+370',
    placeholder: 61234567,
  },
  {
    name: 'Luxembourg',
    code: 'lu',
    dial_code: '+352',
    placeholder: 628123456,
  },
  {
    name: 'Macau (澳門)',
    code: 'mo',
    dial_code: '+853',
    placeholder: 66123456,
  },
  {
    name: 'North Macedonia (Македонија)',
    code: 'mk',
    dial_code: '+389',
    placeholder: 72345678,
  },
  {
    name: 'Madagascar (Madagasikara)',
    code: 'mg',
    dial_code: '+261',
    placeholder: 321234567,
  },
  {
    name: 'Malawi',
    code: 'mw',
    dial_code: '+265',
    placeholder: 991234567,
  },
  {
    name: 'Malaysia',
    code: 'my',
    dial_code: '+60',
    placeholder: 123456789,
  },
  {
    name: 'Maldives',
    code: 'mv',
    dial_code: '+960',
    placeholder: 7712345,
  },
  {
    name: 'Mali',
    code: 'ml',
    dial_code: '+223',
    placeholder: 65012345,
  },
  {
    name: 'Malta',
    code: 'mt',
    dial_code: '+356',
    placeholder: 96961234,
  },
  {
    name: 'Marshall Islands',
    code: 'mh',
    dial_code: '+692',
    placeholder: 2351234,
  },
  {
    name: 'Martinique',
    code: 'mq',
    dial_code: '+596',
    placeholder: 696201234,
  },
  {
    name: 'Mauritania (‫موريتانيا‬‎)',
    code: 'mr',
    dial_code: '+222',
    placeholder: 22123456,
  },
  {
    name: 'Mauritius (Moris)',
    code: 'mu',
    dial_code: '+230',
    placeholder: 52512345,
  },
  {
    name: 'Mayotte',
    code: 'yt',
    dial_code: '+262',
    placeholder: 639012345,
  },
  {
    name: 'Mexico (México)',
    code: 'mx',
    dial_code: '+52',
    placeholder: 2221234567,
  },
  {
    name: 'Micronesia',
    code: 'fm',
    dial_code: '+691',
    placeholder: 3501234,
  },
  {
    name: 'Moldova (Republica Moldova)',
    code: 'md',
    dial_code: '+373',
    placeholder: 62112345,
  },
  {
    name: 'Monaco',
    code: 'mc',
    dial_code: '+377',
    placeholder: 612345678,
  },
  {
    name: 'Mongolia (Монгол)',
    code: 'mn',
    dial_code: '+976',
    placeholder: 88123456,
  },
  {
    name: 'Montenegro (Crna Gora)',
    code: 'me',
    dial_code: '+382',
    placeholder: 67622901,
  },
  {
    name: 'Montserrat',
    code: 'ms',
    dial_code: '+1',
    placeholder: 6644923456,
  },
  {
    name: 'Morocco (‫المغرب‬‎)',
    code: 'ma',
    dial_code: '+212',
    placeholder: 650123456,
  },
  {
    name: 'Mozambique (Moçambique)',
    code: 'mz',
    dial_code: '+258',
    placeholder: 821234567,
  },
  {
    name: 'Myanmar (Burma) (မြန်မာ)',
    code: 'mm',
    dial_code: '+95',
    placeholder: 92123456,
  },
  {
    name: 'Namibia (Namibië)',
    code: 'na',
    dial_code: '+264',
    placeholder: 811234567,
  },
  {
    name: 'Nauru',
    code: 'nr',
    dial_code: '+674',
    placeholder: 5551234,
  },
  {
    name: 'Nepal (नेपाल)',
    code: 'np',
    dial_code: '+977',
    placeholder: 9841234567,
  },
  {
    name: 'Netherlands (Nederland)',
    code: 'nl',
    dial_code: '+31',
    placeholder: 612345678,
  },
  {
    name: 'New Caledonia (Nouvelle-Calédonie)',
    code: 'nc',
    dial_code: '+687',
    placeholder: 751234,
  },
  {
    name: 'New Zealand',
    code: 'nz',
    dial_code: '+64',
    placeholder: 211234567,
  },
  {
    name: 'Nicaragua',
    code: 'ni',
    dial_code: '+505',
    placeholder: 81234567,
  },
  {
    name: 'Niger (Nijar)',
    code: 'ne',
    dial_code: '+227',
    placeholder: 93123456,
  },
  {
    name: 'Nigeria',
    code: 'ng',
    dial_code: '+234',
    placeholder: 8021234567,
  },
  {
    name: 'Niue',
    code: 'nu',
    dial_code: '+683',
    placeholder: 8884012,
  },
  {
    name: 'Norfolk Island',
    code: 'nf',
    dial_code: '+672',
    placeholder: 381234,
  },
  {
    name: 'North Korea (조선 민주주의 인민 공화국)',
    code: 'kp',
    dial_code: '+850',
    placeholder: 1921234567,
  },
  {
    name: 'Northern Mariana Islands',
    code: 'mp',
    dial_code: '+1',
    placeholder: 6702345678,
  },
  {
    name: 'Norway (Norge)',
    code: 'no',
    dial_code: '+47',
    placeholder: 40612345,
  },
  {
    name: 'Oman (‫عُمان‬‎)',
    code: 'om',
    dial_code: '+968',
    placeholder: 92123456,
  },
  {
    name: 'Pakistan (‫پاکستان‬‎)',
    code: 'pk',
    dial_code: '+92',
    placeholder: 3012345678,
  },
  {
    name: 'Palau',
    code: 'pw',
    dial_code: '+680',
    placeholder: 6201234,
  },
  {
    name: 'Palestine (‫فلسطين‬‎)',
    code: 'ps',
    dial_code: '+970',
    placeholder: 599123456,
  },
  {
    name: 'Panama (Panamá)',
    code: 'pa',
    dial_code: '+507',
    placeholder: 61234567,
  },
  {
    name: 'Papua New Guinea',
    code: 'pg',
    dial_code: '+675',
    placeholder: 70123456,
  },
  {
    name: 'Paraguay',
    code: 'py',
    dial_code: '+595',
    placeholder: 961456789,
  },
  {
    name: 'Peru (Perú)',
    code: 'pe',
    dial_code: '+51',
    placeholder: 912345678,
  },
  {
    name: 'Philippines',
    code: 'ph',
    dial_code: '+63',
    placeholder: 9051234567,
  },
  {
    name: 'Poland (Polska)',
    code: 'pl',
    dial_code: '+48',
    placeholder: 512345678,
  },
  {
    name: 'Portugal',
    code: 'pt',
    dial_code: '+351',
    placeholder: 912345678,
  },
  {
    name: 'Puerto Rico',
    code: 'pr',
    dial_code: '+1',
    placeholder: 7872345678,
  },
  {
    name: 'Qatar (‫قطر‬‎)',
    code: 'qa',
    dial_code: '+974',
    placeholder: 33123456,
  },
  {
    name: 'Réunion (La Réunion)',
    code: 're',
    dial_code: '+262',
    placeholder: 692123456,
  },
  {
    name: 'Romania (România)',
    code: 'ro',
    dial_code: '+40',
    placeholder: 712034567,
  },
  {
    name: 'Russia (Россия)',
    code: 'ru',
    dial_code: '+7',
    placeholder: 9123456789,
  },
  {
    name: 'Rwanda',
    code: 'rw',
    dial_code: '+250',
    placeholder: 720123456,
  },
  {
    name: 'Saint Barthélemy',
    code: 'bl',
    dial_code: '+590',
    placeholder: 690001234,
  },
  {
    name: 'Saint Helena',
    code: 'sh',
    dial_code: '+290',
    placeholder: 51234,
  },
  {
    name: 'Saint Kitts and Nevis',
    code: 'kn',
    dial_code: '+1',
    placeholder: 8697652917,
  },
  {
    name: 'Saint Lucia',
    code: 'lc',
    dial_code: '+1',
    placeholder: 7582845678,
  },
  {
    name: 'Saint Martin (Saint-Martin (partie française))',
    code: 'mf',
    dial_code: '+590',
    placeholder: 690001234,
  },
  {
    name: 'Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)',
    code: 'pm',
    dial_code: '+508',
    placeholder: 551234,
  },
  {
    name: 'Saint Vincent and the Grenadines',
    code: 'vc',
    dial_code: '+1',
    placeholder: 7844301234,
  },
  {
    name: 'Samoa',
    code: 'ws',
    dial_code: '+685',
    placeholder: 7212345,
  },
  {
    name: 'San Marino',
    code: 'sm',
    dial_code: '+378',
    placeholder: 66661212,
  },
  {
    name: 'São Tomé and Príncipe (São Tomé e Príncipe)',
    code: 'st',
    dial_code: '+239',
    placeholder: 9812345,
  },
  {
    name: 'Saudi Arabia (السعودية)',
    code: 'sa',
    dial_code: '+966',
    placeholder: 512345678,
  },
  {
    name: 'Senegal (Sénégal)',
    code: 'sn',
    dial_code: '+221',
    placeholder: 701234567,
  },
  {
    name: 'Serbia (Србија)',
    code: 'rs',
    dial_code: '+381',
    placeholder: 601234567,
  },
  {
    name: 'Seychelles',
    code: 'sc',
    dial_code: '+248',
    placeholder: 2510123,
  },
  {
    name: 'Sierra Leone',
    code: 'sl',
    dial_code: '+232',
    placeholder: 25123456,
  },
  {
    name: 'Singapore',
    code: 'sg',
    dial_code: '+65',
    placeholder: 81234567,
  },
  {
    name: 'Sint Maarten',
    code: 'sx',
    dial_code: '+1',
    placeholder: 7215205678,
  },
  {
    name: 'Slovakia (Slovensko)',
    code: 'sk',
    dial_code: '+421',
    placeholder: 912123456,
  },
  {
    name: 'Slovenia (Slovenija)',
    code: 'si',
    dial_code: '+386',
    placeholder: 31234567,
  },
  {
    name: 'Solomon Islands',
    code: 'sb',
    dial_code: '+677',
    placeholder: 7421234,
  },
  {
    name: 'Somalia (Soomaaliya)',
    code: 'so',
    dial_code: '+252',
    placeholder: 71123456,
  },
  {
    name: 'South Africa',
    code: 'za',
    dial_code: '+27',
    placeholder: 711234567,
  },
  {
    name: 'South Korea (대한민국)',
    code: 'kr',
    dial_code: '+82',
    placeholder: 1020000000,
  },
  {
    name: 'South Sudan (‫جنوب السودان‬‎)',
    code: 'ss',
    dial_code: '+211',
    placeholder: 977123456,
  },
  {
    name: 'Spain (España)',
    code: 'es',
    dial_code: '+34',
    placeholder: 612345678,
  },
  {
    name: 'Sri Lanka (ශ්‍රී ලංකාව)',
    code: 'lk',
    dial_code: '+94',
    placeholder: 712345678,
  },
  {
    name: 'Sudan (‫السودان‬‎)',
    code: 'sd',
    dial_code: '+249',
    placeholder: 911231234,
  },
  {
    name: 'Suriname',
    code: 'sr',
    dial_code: '+597',
    placeholder: 7412345,
  },
  {
    name: 'Svalbard and Jan Mayen',
    code: 'sj',
    dial_code: '+47',
    placeholder: 41234567,
  },
  {
    name: 'Sweden (Sverige)',
    code: 'se',
    dial_code: '+46',
    placeholder: 701234567,
  },
  {
    name: 'Switzerland (Schweiz)',
    code: 'ch',
    dial_code: '+41',
    placeholder: 781234567,
  },
  {
    name: 'Syria (‫سوريا‬‎)',
    code: 'sy',
    dial_code: '+963',
    placeholder: 944567890,
  },
  {
    name: 'Taiwan (台灣)',
    code: 'tw',
    dial_code: '+886',
    placeholder: 912345678,
  },
  {
    name: 'Tajikistan',
    code: 'tj',
    dial_code: '+992',
    placeholder: 917123456,
  },
  {
    name: 'Tanzania',
    code: 'tz',
    dial_code: '+255',
    placeholder: 621234567,
  },
  {
    name: 'Thailand (ไทย)',
    code: 'th',
    dial_code: '+66',
    placeholder: 812345678,
  },
  {
    name: 'Timor-Leste',
    code: 'tl',
    dial_code: '+670',
    placeholder: 77212345,
  },
  {
    name: 'Togo',
    code: 'tg',
    dial_code: '+228',
    placeholder: 90112345,
  },
  {
    name: 'Tokelau',
    code: 'tk',
    dial_code: '+690',
    placeholder: 7290,
  },
  {
    name: 'Tonga',
    code: 'to',
    dial_code: '+676',
    placeholder: 7715123,
  },
  {
    name: 'Trinidad and Tobago',
    code: 'tt',
    dial_code: '+1',
    placeholder: 8682911234,
  },
  {
    name: 'Tunisia (‫تونس‬‎)',
    code: 'tn',
    dial_code: '+216',
    placeholder: 20123456,
  },
  {
    name: 'Turkey (Türkiye)',
    code: 'tr',
    dial_code: '+90',
    placeholder: 5012345678,
  },
  {
    name: 'Turkmenistan',
    code: 'tm',
    dial_code: '+993',
    placeholder: 66123456,
  },
  {
    name: 'Turks and Caicos Islands',
    code: 'tc',
    dial_code: '+1',
    placeholder: 6492311234,
  },
  {
    name: 'Tuvalu',
    code: 'tv',
    dial_code: '+688',
    placeholder: 901234,
  },
  {
    name: 'U.S. Virgin Islands',
    code: 'vi',
    dial_code: '+1',
    placeholder: 3406421234,
  },
  {
    name: 'Uganda',
    code: 'ug',
    dial_code: '+256',
    placeholder: 712345678,
  },
  {
    name: 'Ukraine (Україна)',
    code: 'ua',
    dial_code: '+380',
    placeholder: 501234567,
  },
  {
    name: 'United Arab Emirates (الإمارات)',
    code: 'ae',
    dial_code: '+971',
    placeholder: 501234567,
  },
  {
    name: 'United Kingdom',
    code: 'gb',
    dial_code: '+44',
    placeholder: 7400123456,
  },
  {
    name: 'United States',
    code: 'us',
    dial_code: '+1',
    placeholder: 2015550123,
  },
  {
    name: 'Uruguay',
    code: 'uy',
    dial_code: '+598',
    placeholder: 94231234,
  },
  {
    name: 'Uzbekistan (Oʻzbekiston)',
    code: 'uz',
    dial_code: '+998',
    placeholder: 912345678,
  },
  {
    name: 'Vanuatu',
    code: 'vu',
    dial_code: '+678',
    placeholder: 5912345,
  },
  {
    name: 'Vatican City (Città del Vaticano)',
    code: 'va',
    dial_code: '+39',
    placeholder: 3123456789,
  },
  {
    name: 'Venezuela',
    code: 've',
    dial_code: '+58',
    placeholder: 4121234567,
  },
  {
    name: 'Vietnam (Việt Nam)',
    code: 'vn',
    dial_code: '+84',
    placeholder: 912345678,
  },
  {
    name: 'Wallis and Futuna (Wallis-et-Futuna)',
    code: 'wf',
    dial_code: '+681',
    placeholder: 821234,
  },
  {
    name: 'Western Sahara (‫الصحراء الغربية‬‎)',
    code: 'eh',
    dial_code: '+212',
    placeholder: 650123456,
  },
  {
    name: 'Yemen (‫اليمن‬‎)',
    code: 'ye',
    dial_code: '+967',
    placeholder: 712345678,
  },
  {
    name: 'Zambia',
    code: 'zm',
    dial_code: '+260',
    placeholder: 955123456,
  },
  {
    name: 'Zimbabwe',
    code: 'zw',
    dial_code: '+263',
    placeholder: 712345678,
  },
  {
    name: 'Åland Islands',
    code: 'ax',
    dial_code: '+358',
    placeholder: 412345678,
  },
];
